import React, { useState, useEffect, useCallback } from 'react';
import { MenuItem } from 'semantic-ui-react';
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components';
import { RegistrationForm } from 'components/organisms/AuthenticationModal/RegistrationForm';
import { LoginForm } from 'components/organisms/AuthenticationModal/LoginForm';
import {
  Button,
  ButtonEnums,
  Checkbox,
  Divider,
  Link,
  LinkEnums,
  NotificationBanner,
  NotificationBannerEnums,
  Tab,
  LabeledIcon,
} from 'components/atoms';
import { AuthenticationPageToShow } from 'modules/layouts/constants';
import { Routes } from 'constants/index';
import { ReactComponent as Correct } from 'resources/images/icon-dev-ic-check-circle.svg';
import { ReactComponent as IconError } from 'resources/images/ic-error.svg';
import { User as UserTypes } from 'mxp-schemas';
import { Redirect } from 'react-router';
import { getPath } from 'utils';
import iconBeenHere from 'resources/images/ic-been-here.svg';
import iconUpdate from 'resources/images/icon-dev-ic-update.svg';

interface LoginAndRegistrationProps {
  register: (email: string, newUser: UserTypes.NewUser, blockedEmailDomains: string[]) => void;
  closeAuthenticationModal: () => void;
  isAuthenticationModalVisible: boolean;
  showLogin: ({ email }: { email: string }) => void;
  showRegistration: ({ email }: { email: string }) => void;
  login: (email: string, password: string, remember: boolean, blockedEmailDomains: string[]) => void;
  error?: State.UserError | null;
  loading: boolean;
  isAuth: boolean | null;
  currentPathname: string;
  pageToShow: AuthenticationPageToShow;
  isCheckbox?: boolean;
  userPasswordUpdated: boolean;
  clearUserError: () => void;
  registrationLoading: boolean;
  inviteId?: string;
  inviteData?: UserTypes.MembershipInviteData;
  getMembershipInviteData?: (inviteId: string) => Promise<void>;
  blockedEmailDomains: string[];
}

export const LoginAndRegistration: React.FC<LoginAndRegistrationProps> = ({
  isAuthenticationModalVisible,
  pageToShow,
  showLogin,
  showRegistration,
  login,
  loading,
  register,
  error,
  isAuth,
  currentPathname,
  isCheckbox = false,
  userPasswordUpdated,
  clearUserError,
  registrationLoading,
  inviteId,
  getMembershipInviteData,
  inviteData,
  blockedEmailDomains,
}) => {
  const isLoginTabActive =
    pageToShow === AuthenticationPageToShow.LOGIN_MODAL || pageToShow === AuthenticationPageToShow.LOGIN_PAGE;
  const isModal =
    pageToShow === AuthenticationPageToShow.LOGIN_MODAL || pageToShow === AuthenticationPageToShow.REGISTRATION_MODAL;

  const defaultRegistrationFormState = {
    firstName: '',
    lastName: '',
    email: '',
    reEmail: '',
    password: '',
    exclusiveContent: true,
    businessContent: true,
    countryCode: '',
    mobileNumber: {
      phoneNumber: '',
      dialCode: '',
      countryCode: '',
    },
    optToReceiveSMS: false,
  };
  const defaultLoginFormState = {
    email: '',
    password: '',
    keepMeLoggedIn: true,
  };

  const [isRegistrationFormValid, setRegistrationFormValid] = useState(false);
  const [isLoginFormValid, setLoginFormValid] = useState(false);
  const [registrationForm, setRegistrationForm] = useState(defaultRegistrationFormState);
  const [loginForm, setLoginForm] = useState(defaultLoginFormState);
  const [loginSubmitButtonDisabled, setLoginSubmitButtonDisabled] = useState(!isLoginFormValid);

  useEffect(() => {
    // make sure we dont start with error
    clearUserError();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // tslint:disable-next-line: prettier
    const hasError = Boolean(error?.errorCode === UserTypes.UserErrorCodes.LOGIN_NOT_FOUND);
    const loginDisabled = !isLoginFormValid || hasError;
    setLoginSubmitButtonDisabled(loginDisabled);
  }, [setLoginSubmitButtonDisabled, isLoginFormValid, error]);

  // unlock login button on loginForm changes
  useEffect(() => {
    const loginDisabled = !isLoginFormValid;
    setLoginSubmitButtonDisabled(loginDisabled);
  }, [setLoginSubmitButtonDisabled, isLoginFormValid, loginForm]);

  // firm billing
  useEffect(() => {
    if (inviteId && getMembershipInviteData) {
      getMembershipInviteData(inviteId);
    }
  }, [inviteId, getMembershipInviteData]);

  const submitRegistration = () => {
    if (isRegistrationFormValid && !loading && !registrationLoading) {
      register(registrationForm.email, registrationForm, blockedEmailDomains);
    }
  };

  const submitLogin = useCallback(() => {
    if (!loading) {
      login(loginForm.email, loginForm.password, loginForm.keepMeLoggedIn, blockedEmailDomains);
    }
  }, [loading, loginForm, login, blockedEmailDomains]);

  const handleKeepMeLoggedInClick = (e: any): void => {
    setLoginForm({ ...loginForm, keepMeLoggedIn: Boolean(e.target.checked) });
  };

  const goToRegistrationTab = useCallback(
    (e?: React.SyntheticEvent<HTMLElement>) => {
      if (e) {
        e.preventDefault();
      }

      showRegistration({ email: loginForm.email });
    },
    [showRegistration, loginForm]
  );

  const goToLoginTab = useCallback(
    (e?: React.SyntheticEvent<HTMLElement>) => {
      if (e) {
        e.preventDefault();
      }
      showLogin({ email: registrationForm.email });
    },
    [showLogin, registrationForm]
  );

  if (isAuth && isAuthenticationModalVisible) {
    return <Redirect to={currentPathname} />;
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {/* if there's an invite data then check if already have an account, if yes = login tab, if no = registration tab, then else default */}
        <StyledTab
          activeIndex={inviteData?.email ? (inviteData?.isAlreadyHaveAccount ? 0 : 1) : Number(!isLoginTabActive)}
          panes={[
            {
              menuItem: (
                <StyledMenuItem data-testid="authentication-already-registered" key="login-tab">
                  Already have an account?
                </StyledMenuItem>
              ),
              onClick: goToLoginTab,
              render: () => (
                <Tab.Pane>
                  <Description isRegularFont={!isModal}>Log in with your account</Description>
                  <LoginErrorMessages
                    goToRegistrationTab={goToRegistrationTab}
                    loginName={loginForm.email}
                    error={error}
                    userPasswordUpdated={userPasswordUpdated}
                  />
                  <LoginForm
                    loginForm={loginForm}
                    setLoginForm={setLoginForm}
                    setFormValid={setLoginFormValid}
                    inviteData={inviteData}
                  />
                  {isModal && <SpaceAroundDivider />}
                  <ModalAction isCentered={isModal}>
                    <LoginActions>
                      <SubmitButton
                        size={isModal ? ButtonEnums.sizes.small : ButtonEnums.sizes.medium}
                        loading={loading}
                        onClick={submitLogin}
                        testId="login"
                        variant={ButtonEnums.variants.primary}
                        disabled={loginSubmitButtonDisabled}
                      >
                        Log in
                      </SubmitButton>
                      {isCheckbox && (
                        <StyledCheckbox
                          onChange={handleKeepMeLoggedInClick}
                          checked={loginForm.keepMeLoggedIn}
                          id="login"
                          testId="login"
                          label="Keep me logged in"
                          width={18}
                          height={18}
                          isCentered={isModal}
                        />
                      )}
                    </LoginActions>
                  </ModalAction>
                </Tab.Pane>
              ),
            },
            {
              menuItem: (
                <StyledMenuItem data-testid="authentication-new-to-aicpa" key="register-tab">
                  Create an account
                </StyledMenuItem>
              ),
              onClick: goToRegistrationTab,
              render: () => (
                <Tab.Pane>
                  <RegistrationErrorMessages goToLoginTab={goToLoginTab} error={error} />
                  <RegistrationForm
                    registrationForm={registrationForm}
                    setRegistrationForm={setRegistrationForm}
                    setFormValid={setRegistrationFormValid}
                    inviteData={inviteData}
                  />
                  {isModal && <SpaceAroundDivider />}
                  <ModalAction isCentered={isModal}>
                    <StyledSubmitButton
                      size={isModal ? ButtonEnums.sizes.small : ButtonEnums.sizes.medium}
                      loading={registrationLoading}
                      onClick={submitRegistration}
                      testId="registration"
                      variant={ButtonEnums.variants.primary}
                      disabled={!isRegistrationFormValid || registrationLoading}
                    >
                      Submit
                    </StyledSubmitButton>
                  </ModalAction>
                </Tab.Pane>
              ),
            },
          ]}
        />
      </form>
    </>
  );
};

const SpaceAroundDivider = styled(Divider)`
  &&& {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
`;

const StyledTab = styled(Tab)`
  .ui.text.menu {
    margin-top: ${props => props.theme.pxToRem(24)};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.s} !important;
  }
`;

export const ModalAction = styled.div<ThemedStyledProps<{ isCentered: boolean | void }, DefaultTheme>>`
  ${props => `
  display: flex;
  justify-content: ${props.isCentered ? 'center' : 'start'}
  `}
`;

export const SubmitButton = styled(Button)`
  width: ${props => props.theme.pxToRem(250)};
`;

const StyledSubmitButton = styled(Button)`
  width: ${props => props.theme.pxToRem(250)};
`;

export const LoginActions = styled.div`
  margin: ${props => props.theme.pxToRem(10)} 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledCheckbox = styled(Checkbox)<ThemedStyledProps<{ isCentered: boolean | void }, DefaultTheme>>`
  ${props => `
  text-align: ${props.isCentered ? 'center' : 'left'};
  `}
  &&& {
    margin-top: ${props => props.theme.pxToRem(24)};
    font-weight: ${props => props.theme.fontWeights.light};
    font-size: ${props => props.theme.fontSizes.xxs};
  }
  &.ui.checkbox .box,
  &.ui.checkbox label {
    display: inline-block;
  }
  &.ui.checkbox input:checked ~ label {
    font-weight: ${props => props.theme.fontWeights.light};
    font-size: ${props => props.theme.fontSizes.xxs};
  }
`;

const StyledIconError = styled(IconError)`
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  align-self: flex-start;
  ${props => props.theme.mediaQueries.mobileOnly} {
    align-self: center;
  }
  transform: rotateX(180deg);
`;

const Description = styled.p<ThemedStyledProps<{ isRegularFont?: boolean | void }, DefaultTheme>>`
  font-weight: ${props => (props.isRegularFont ? props.theme.fontWeights.regular : props.theme.fontWeights.light)};
  text-align: left;
  margin-bottom: ${props => props.theme.pxToRem(24)};
  font-size: ${props => props.theme.fontSizes.s};
  line-height: 1.5;
  b {
    font-weight: ${props => props.theme.fontWeights.medium};
  }
`;

interface NotFoundNotificationBanner {
  loginName: string;
  goToRegistrationTab: () => void;
}
const NotFoundNotificationBanner: React.FC<NotFoundNotificationBanner> = ({ loginName, goToRegistrationTab }) => {
  const [cachedLogin] = useState(loginName);
  return (
    <NotificationBannerStyled
      testId="error-login-not-found-banner"
      childrenTestId="error-login-not-found-children"
      variant={NotificationBannerEnums.variant.blue}
      icon={<StyledIconError />}
    >
      We couldn’t find an account that matches <b>{cachedLogin}</b>
      <br />• Please check your spelling and try again. <br />• New to AICPA.org?{' '}
      <Link onClick={goToRegistrationTab} testId="go-to-registration" to={'/'}>
        Create a free account{' '}
      </Link>
      <br />
    </NotificationBannerStyled>
  );
};

interface LoginErrorMessagesProps {
  error?: State.UserError | null;
  goToRegistrationTab: () => void;
  loginName: string;
  userPasswordUpdated?: boolean;
}

export const LoginErrorMessages: React.FC<LoginErrorMessagesProps> = ({
  error,
  goToRegistrationTab,
  loginName,
  userPasswordUpdated,
}) => {
  return (
    <>
      {userPasswordUpdated && (
        <NotificationSuccessStyled
          testId="pass-updated-banner"
          childrenTestId="pass-updated-children"
          variant={NotificationBannerEnums.variant.green}
          icon={<StyledCorrectIcon />}
        >
          Success. Your password has been updated.
        </NotificationSuccessStyled>
      )}
      {error?.errorCode === UserTypes.UserErrorCodes.E0000004 && (
        <NotificationBannerStyled
          testId="error-incorrect-pass-banner"
          childrenTestId="error-incorrect-pass-children"
          variant={NotificationBannerEnums.variant.red}
          icon={<StyledIconError />}
        >
          The email or password is invalid. Please try again.
        </NotificationBannerStyled>
      )}

      {error?.errorCode === UserTypes.UserErrorCodes.LOCKED_OUT && (
        <NotificationBannerStyled
          testId="error-locked-out-banner"
          childrenTestId="error-locked-out-children"
          variant={NotificationBannerEnums.variant.red}
          icon={<StyledIconError />}
        >
          Sorry, the email address or password is incorrect. For security reasons, please wait <b>5 minutes</b> before
          trying again.
          <StyledParagraph>
            Would you like to{' '}
            <Link type={LinkEnums.type.inlineLink} to={getPath(Routes.FORGOT_EMAIL)} testId="forgot-email-address">
              {' '}
              recover your email address
            </Link>{' '}
            or{' '}
            <Link
              type={LinkEnums.type.inlineLink}
              to={getPath(Routes.FORGOT_PASSWORD)}
              testId="forgot-password-address"
            >
              {' '}
              reset your password
            </Link>
            ?
          </StyledParagraph>
        </NotificationBannerStyled>
      )}
      {error?.errorCode === UserTypes.UserErrorCodes.LOGIN_NOT_FOUND && loginName && (
        <NotFoundNotificationBanner goToRegistrationTab={goToRegistrationTab} loginName={loginName} />
      )}

      {error?.errorCode === UserTypes.UserErrorCodes.DATA_NOT_YET_MIGRATED && (
        <NotificationBannerStyled
          testId="error-data-not-migrated"
          childrenTestId="error-data-not-migrated"
          variant={NotificationBannerEnums.variant.blue}
          icon={<StyledIconError />}
        >
          Your account data is currently being migrated, please try again later
        </NotificationBannerStyled>
      )}
    </>
  );
};

interface RegistrationErrorMessagesProps {
  error?: State.UserError | null;
  goToLoginTab: () => void;
}

const RegistrationErrorMessages: React.FC<RegistrationErrorMessagesProps> = ({ error, goToLoginTab }) => {
  return (
    <>
      {error?.errorCode === UserTypes.UserErrorCodes.USER_ALREADY_EXISTS && (
        <NotificationBanner
          testId="email-exists-banner"
          childrenTestId="email-exists-children"
          variant={NotificationBannerEnums.variant.green}
          icon={<StyledCorrectIcon />}
        >
          There is already an account registered with this email address. Please&nbsp;
          <Link testId="proceed-to-log-in" to=" " onClick={goToLoginTab}>
            log in
          </Link>
          &nbsp;or&nbsp;
          <Link testId="proceed-to-reset-password" to={getPath(Routes.FORGOT_PASSWORD)}>
            reset your password
          </Link>
          .
          <br />
        </NotificationBanner>
      )}
      {error?.errorCode === UserTypes.UserErrorCodes.REGISTRATION_ERROR && (
        <NotificationBannerStyled
          testId="error-incorrect-pass-banner"
          childrenTestId="error-incorrect-pass-children"
          variant={NotificationBannerEnums.variant.red}
          icon={<StyledIconError />}
        >
          An error occurred when trying to create your account. Please contact member services if the issue persists.
        </NotificationBannerStyled>
      )}
    </>
  );
};

const NotificationBannerStyled = styled(NotificationBanner)`
  &&&&& {
    margin: 0 0 ${props => props.theme.pxToRem(32)} 0;
    padding: ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(16)};
    min-height: ${props => props.theme.pxToRem(68)};
  }
`;

const NotificationSuccessStyled = styled(NotificationBannerStyled)`
  &&&&& {
    padding: ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(16)};
  }
`;

const StyledCorrectIcon = styled(Correct)`
  color: ${props => props.theme.colors.interfaceGreen};
  flex: 0 0 ${props => props.theme.pxToRem(24)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    align-self: center;
  }
`;

const StyledParagraph = styled.p`
  margin-top: ${props => props.theme.pxToRem(18)};
`;

interface HelpSectionProps {
  className?: string;
  viewTextForAnalytics?: string;
}

export const HelpSection: React.FC<HelpSectionProps> = React.memo(({ className, viewTextForAnalytics }) => {
  return (
    <StyledDiv className="Help-Section">
      <>
        <StyledLabeledIcon
          icon={<StyledIcon src={iconBeenHere} alt="icon checkmark" />}
          label="Our guarantee"
          mobileLabel="Our guarantee"
        />
        <StyledExtraInfo>
          Every purchase you make from the AICPA & CIMA is safe and secure. We also guarantee 100% customer satisfaction
          on most of our products. If you’re not satisfied with your purchase, please contact us.
        </StyledExtraInfo>
        <StyledLabeledIcon
          icon={<StyledIcon src={iconUpdate} alt="icon-update" />}
          label="Our refund policy"
          mobileLabel="Our refund policy"
        />
        <StyledExtraInfo>
          To start the return process, please contact us first. View all products{' '}
          <Link
            testId="about-refund-policy"
            to={getPath(Routes.REFUND_POLICY)}
            viewTextForAnalytics={viewTextForAnalytics}
          >
            eligible for refunds
          </Link>
          .
        </StyledExtraInfo>
      </>
    </StyledDiv>
  );
});

const StyledDiv = styled.div`
  ${props => props.theme.mediaQueries.mobileOnly} {
    display: flex;
    flex-wrap: wrap;
  }
`;
const StyledIcon = styled.img`
  width: ${props => props.theme.pxToRem(16)};
  height: ${props => props.theme.pxToRem(16)};
  margin-right: ${props => props.theme.pxToRem(8)};
  align-self: center;
  display: inline-flex;
`;

const StyledLabeledIcon = styled(LabeledIcon)`
  &&&& {
    display: inline-flex;
    color: ${props => props.theme.colors.neutralGrey8};
    font-size: ${props => props.theme.fontSizes.xxs};
    font-weight: ${props => props.theme.fontWeights.medium};
    line-height: 1.5;
    letter-spacing: ${props => props.theme.pxToRem(0.22)};
  }
`;

const StyledExtraInfo = styled.p`
  margin-left: ${props => props.theme.pxToRem(24)};
  color: ${props => props.theme.colors.neutralGrey8};
  font-size: ${props => props.theme.fontSizes.xxs};
  font-weight: ${props => props.theme.fontWeights.light};
  line-height: 1.5;
  letter-spacing: ${props => props.theme.pxToRem(0.22)};
  width: ${props => props.theme.pxToRem(600)};
  padding-bottom: ${props => props.theme.pxToRem(26)};
`;
