import { createAction } from 'redux-actions';
import { SEND_MESSAGE } from 'mxp-graphql-queries';
import { default as request } from 'utils/GraphQLClient';
import { Dispatch } from 'redux';
import { getLocalUser, getUser, getUserLocation } from 'modules/user/actions';
import { getLocalCheckout } from 'modules/checkout/actions';
import { getCurrency } from 'modules/products/actions';
import { getCart, getCartOrMigrate, updatePreferredCurrencyByCart } from 'modules/cart';

import { getSupervisorDashboard } from 'modules/cimasupervisor/actions';
import * as actions from 'modules/postLoginActionsTable';
import { StorageNames } from 'constants/index';
import { isServer } from 'utils';
import { getSessionStorageItem, removeSessionStorageItem } from 'utils/sessionStorage';
import { appInitializedSelector } from 'modules/app/selectors';
import { User as UserTypes, Cart } from 'mxp-schemas';
import { getLocalStorageItem } from 'utils/localStorage';
import { getPracticalExperienceRequirement, setMembershipEvent } from 'modules/membership/actions';

// ------------------------------------
// Actions
// ------------------------------------
export const resetAppInitialized: any = createAction('app/RESET_APP_INITIALIZED');

export const loadConstants: any = createAction('app/LOAD_CONSTANTS');

export const sendMessage: any = createAction('app/SEND_LOG', (message: string) => () => {
  return request(SEND_MESSAGE, { message });
});

export const rehydrateUserData: any = createAction(
  'app/HYDRATE_USER_DATA',
  (isAuth: boolean, session?: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();

    // after modal window user login rehydrateUserData fired again and appInitialized must be reset
    if (appInitializedSelector(state)) dispatch(resetAppInitialized());

    if (isServer) {
      // This action is only relevant for the client side rendering, do nothing on the server
      return Promise.resolve();
    }

    // anonymous user rehydration
    if (!isAuth) {
      await dispatch(getUserLocation());
      // just get anon cart
      if (session === UserTypes.SessionType.EXTERNAL_SESSION) {
        dispatch(getSupervisorDashboard());
      }
      return dispatch(getCart());
      // do not proceed for non Auth;
    }

    // Logged user rehydration
    dispatch(getLocalUser());
    dispatch(getLocalCheckout());
    dispatch(getUser());

    const actionsInProgress: any = [];

    // check if there's an existing purchase
    await dispatch(getCurrency());
    await dispatch(getUserLocation());
    await dispatch(updatePreferredCurrencyByCart());

    // get currency first before migrating cart if there's one so we can pass the correct one.
    const cartResult = await dispatch(getCartOrMigrate());
    const cart = cartResult?.payload?.payload;

    // Retrieve stored state for this cart and apply it, in case it was cleared by a refresh during checkout
    const cartState = getLocalStorageItem(StorageNames.activeCart) as Cart.ActiveCart | null;
    if (cartState && cart?.id === cartState.cartId && cart?.lineItems?.length) {
      dispatch(setMembershipEvent('isClickedMembershipUpgrade', cartState.isClickedMembershipUpgrade));
      if (cartState.isPERCompleted) {
        await dispatch(getPracticalExperienceRequirement());
      }
    }

    // dispatch actions that happened before login
    const actionsToDispatchAfterLogin: any = getSessionStorageItem(StorageNames.actionsToDispatchAfterLogin) || [];
    if (actionsToDispatchAfterLogin.length) {
      actionsToDispatchAfterLogin.forEach((action: { type: string; payload: any }) => {
        const actionCreator: any = Object.values(actions).find(creator => creator().type === action.type);
        if (!actionCreator) return;

        actionsInProgress.push(dispatch(actionCreator(...action.payload)));
      });
    }
    removeSessionStorageItem(StorageNames.actionsToDispatchAfterLogin);

    return Promise.all(actionsInProgress);
  }
);
