/* tslint:disable no-object-literal-type-assertion */
import { createAction } from 'redux-actions';
import { push, getLocation } from 'connected-react-router';
import { generatePath, matchPath } from 'react-router-dom';
import queryString, { parse } from 'query-string';
import { membershipActionNames, initialState } from './constants';
import { RenewMembershipOptions, Routes, StorageNames } from '../../constants';
import { Dispatch } from 'redux';
import { staticLandingPageMainColumnSelector } from '../staticLandingPagesContent/selectors';
import { default as request, updateContext } from 'utils/GraphQLClient';
import {
  areAllTruthy,
  getModuleNameFromPath,
  getPath,
  getRejoiningFeeSlug,
  hasTruthyValue,
  shouldAddOnProductBeIncluded,
  setSessionStorageItem,
} from 'utils';
import { ContentfulHelpers, User, Admin as AdminUtils } from 'mxp-utils';
import { Product, MembershipTypes, Salesforce, ExemptionLevels, Contentful, User as UserSchema, Cart } from 'mxp-schemas';
import {
  userMembershipPackageSelector,
  membershipTypesSelector,
  userMembershipTypeSelector,
  userMembershipTierSelector,
  membershipSeeMoreSectionSelector,
  membershipSeeMoreRelatedAddOnSelector,
  membershipFilterCredentialByCategorySelector,
  membershipFilterRelatedAddOnByCategorySelector,
  membershipFirstPageOfAddOnProductsSelector,
  membershipFirstPageOfCredentialProductsSelector,
  membershipSeeMoreCredentialSelector,
  activeMembershipSubscriptionSelector,
  canceledMembershipSubscriptionSelector,
  isRenewalSelector,
  membershipSubscriptionsSelector,
  membershipRelatedAddonsListSelector,
  membershipSubscriptionsFetchedSelector,
  membershipAddonsFetchedSelector,
  membershipCredentialApplicationSelector,
  membershipEligibleCredentialSelector,
  membershipEligibleSectionSelector,
  membershipFLPProductsSelector,
  sectionsCredentialsRenewalSelector,
  currentMembershipSubscriptionSelector,
  existingCredentialSelector,
  existingSectionSelector,
  membershipEventSelector,
  membershipPropPageURLSelector,
  filteredCIMAMembershipTypesSelector,
  membershipProductSelector,
  isCimaRenewalSeasonSelector,
  clickedMembershipUpgradeSelector,
  practicalExperienceRequirementStatusSelector,
  membershipFilterRelatedPathwaysByCategorySelector,
  isCimaMembershipJourneySelector,
  membershipSeeMoreRelatedPathwaySelector,
  membershipRelatedPathwaysSelector,
  numberOfDowngradesSelector,
  isComingFromPropPageSelector,
  isCredentialsJourneySelector,
  membershipInviteDataSelector,
  clickedFcmaPropPageSelector,
  isCimaCandidateTypeSelector,
  isRenewalsJourneySelector,
  isCimaRegularLapsedSelector,
  isCimaPqCandidateRenewalSelector,
  selectedCimaMembershipKeyByUserChoiceSelector,
  selectedAttestationsSelector,
  inactiveMembershipProduct,
  isCimaPQCandidateLapsedSelector,
  currentMembershipProduct,
  currentCredentialProducts,
  currentSectionProducts,
  isCimaCgmaAffiliateLapsedSelector,
  fcmaCredentialProductSelector,
  isAddFcmaCredentialInCartSelector,
  isAllowToAddFcmaInCartSelector,
  isAicpaMemberFiveYearsLapsedSelector,
  isAicpaMemberLapsedSelector,
  isExpressRenewalSelector,
  userMembershipBenefitsSelector,
  isMembershipSectionsFetchedSelector,
} from './selectors';
import { getMembershipCode, getTypeSelector, getCimaMembershipTypeSelector } from '../startup/selectors';
import {
  ADD_MEMBERSHIP_TO_CART,
  GET_ADDONS_BY_VARIANT_SKU,
  GET_PRODUCTS_BY_TYPE,
  GET_PRODUCTS_BY_TYPE_FLAT_CARDS,
  GET_PRODUCT_ITEM,
  GET_BENEFITS_BY_VARIANT_SKU,
  GET_MEMBERSHIP_SKUS_WITH_BENEFITS,
  SEARCH_INDIVIDUAL,
  UPDATE_APPLICATION_CREDENTIALS,
  GET_MEMBERSHIP_INVITE,
  QUERY_ORGANIZATION_BY_ACCOUNT_ID,
  QUERY_MEMBERSHIP_PRODUCT_BLOCK,
  QUERY_PER_RECORD,
  QUERY_EXEMPTION_ENGINE,
  MUTATE_PERSON_EXAM_EXEMPTION,
  QUERY_EXEMPTION_LEVEL_REFERENCE_BY_ID,
  MUTATE_SAVE_PER_RECORDS,
  QUERY_ORGANIZATIONS_BY_NAME,
  MUTATE_PERSON_EXAM_STATUS,
  UPDATE_MEMBERSHIP,
  UPDATE_CREDENTIAL_SECTION,
  GET_ALL_PRODUCTS_PATHWAY,
  QUERY_ENTRY_LEVEL,
  GET_PER_FINANCIAL_BODIES,
  GET_MEMBERSHIP_INVITE_DATA,
  QUERY_ATTESTATIONS,
  REMOVE_PER_MODULE,
} from 'mxp-graphql-queries';
import { appliedPromoCodeSelector, setCart } from 'modules/cart';
import { acceptFirmMembershipInvite } from 'modules/user/actions';
import {
  applicationSelector,
  isAuthSelector,
  isUserMemberSelector,
  learningPathwaySelector,
  personAccountDataSelector,
  cimaMembershipsTermTypeSelector,
  isCimaMemberSelector,
  isEPA1CompletedSelector,
  isEPA2CompletedSelector,
  cimaMembershipSelector,
  customerInactiveMembershipsSelector,
  inactiveMembershipsBodySelector,
  inactiveMembershipsTermTypeSelector,
  customerInactiveCredentialsSelector,
  currentJourneyLearningPathwaySelector,
  cimaMembershipTermIsTenYearsSelector,
  isUserStudyingSelector,
  isConditionalExemptionStudentSelector,
  isUserMemberSuspendedSelector,
  isUserMemberLapsedSelector,
  personChosenExemptionLevelSelector,
  qualificationLevelsSelector,
} from 'modules/user/selectors';
import { userHasBeenLicensedBeforeSelector } from 'modules/personLevelExemption';
import {
  productsListDataLineItemsSelector,
  flpProductListDataSelector,
  hasFcmaCredentialProductSelector,
  productCurrencySelector,
} from 'modules/products/selectors';
import { isAdminPortalSelector, constantsSelector } from 'modules/app/selectors';
import { CONSTANTS } from 'modules/app/constants';
import {
  cartCredentialsSelector,
  credentialApplicationPartSelectors,
  cartIdSelector,
  cartVersionSelector,
  appliedPromoCodeCustomExemptionFields,
} from 'modules/cart/selectors';

import moment from 'moment-timezone';
import {
  isFirmAffiliatedForCPEAOrPCPSSelector,
  selectedBenefitsSelector,
  isFLPSwitchSelector,
  isFLPUpgradeSelector,
  isL7CandidateUpgradeSelector,
  individualInitialSearchSelector,
  individualQuerySelector,
  isUserRegularAicpaMemberSelector,
} from 'modules/membership/selectors';
import {
  exemptionLevelEntrySelector,
  selectedExemptReferenceLevelSelector,
  selectedExemptionSelector,
  prevSelectedExemptReferenceSelector,
} from 'modules/exemptionEngine/selector';
import { setPrevSelectedExemptReference } from 'modules/exemptionEngine/action';
import { resetSalesTax } from 'modules/checkout/actions';
import { getESProductBySku } from 'modules/search/actions';
import { IndividualSearch } from 'mxp-schemas/src/types/membership';
import { QualificationLevel } from 'mxp-schemas/src/types/user';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { USE_CR_682, USE_NEW_MEMBERSHIP_AICPA, USE_CR_723_REMOVE_PRORATION } from 'modules/featureToggle/constants';
import { getProductBySku } from 'modules/products/actions';
import { setLocalStorageItem } from 'utils/localStorage';

export const isLoading = createAction(membershipActionNames.IS_LOADING);
export const isNotLoading = createAction(membershipActionNames.IS_NOT_LOADING);

const membershipProductBlockSlug = {
  [Product.MembershipApplicationType.AICPA]: 'default',
  [Product.MembershipApplicationType.AICPA_CENTER_MEMBERSHIP]: 'default-center-membership',
  [Product.MembershipApplicationType.CIMA]: MembershipTypes.DefaultCimaMembershipProductBlockSlug,
  [Product.MembershipApplicationType.CIMA_MIP]: 'become-a-member-in-practice',
  [Product.MembershipApplicationType.CIMA_FLP]: 'default-cima-flp-membership',
};

export const fetchMembershipTypes: any = createAction(
  membershipActionNames.GET_MEMBERSHIP_TYPES,
  async (journeyType?: Product.MembershipApplicationType, isFromProfessionalBodies = false) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      dispatch(isLoading());
      const state = getState();
      const existingList = membershipTypesSelector(state);
      const isAdmin = isAdminPortalSelector(state);
      const isAuth = isAuthSelector(state);
      const isImpersonation = Boolean(isAuth) && isAdmin;
      const isUserMember = isUserMemberSelector(state);
      const preselectedType = getTypeSelector(state);
      const cancelledMembershipSubscription = canceledMembershipSubscriptionSelector(state); // when on lapsed membership
      const isUserMemberLapsed = isUserMemberLapsedSelector(state);
      const perStatus = practicalExperienceRequirementStatusSelector(state);
      const isPERStatusCompleted = perStatus === MembershipTypes.PERPortfolioStatus.COMPLETED;
      const learningPathway = learningPathwaySelector(state) || '';

      const membershipTypesListIsCima = existingList?.list?.some(
        item =>
          item.slug === MembershipTypes.CIMALearningPathwaySlug.CIMA_PQ ||
          item.slug === MembershipTypes.CIMALearningPathwaySlug.FLP ||
          item.slug === MembershipTypes.CIMALearningPathwaySlug.APPRENTICESHIP
      );
      const refetchData =
        journeyType === Product.MembershipApplicationType.CIMA
          ? membershipTypesListIsCima
            ? false
            : true
          : membershipTypesListIsCima;

      if (existingList?.list?.length && !isFromProfessionalBodies && !refetchData) {
        return { list: existingList.list };
      }

      const landingPage = staticLandingPageMainColumnSelector(state);

      const landingPageMembershipSubBlock = landingPage?.find(
        (item: any) => item?.contentType === ContentfulHelpers.BLOCK_TYPES.MEMBERSHIP_PRODUCT_BLOCK
      );

      const list = (landingPageMembershipSubBlock as any)?.cmsProducts;

      if (list) {
        return { list };
      }

      try {
        // if journeyType is not set, it will query aicpa membership as default
        const membershipQuery = QUERY_MEMBERSHIP_PRODUCT_BLOCK(
          membershipProductBlockSlug[journeyType || Product.MembershipApplicationType.AICPA]
        );

        const response = await request(membershipQuery);

        const cmsProductResponse = response.block?.[0]?.cmsProducts;
        const joiningFee = response.block?.joiningFee;

        if (!cmsProductResponse) {
          throw new Error('Membership Types not found.');
        }

        if (journeyType === Product.MembershipApplicationType.CIMA) {
          return { list: cmsProductResponse };
        }

        if (cancelledMembershipSubscription) {
          return { list: cmsProductResponse };
        }

        if (
          !isImpersonation &&
          (isUserMember || isUserMemberLapsed) &&
          journeyType !== Product.MembershipApplicationType.AICPA_CENTER_MEMBERSHIP
        ) {
          const activeMembershipSubscription = activeMembershipSubscriptionSelector(state);
          const isSubscribedToFlp = AdminUtils.isFlpPathway(learningPathway);
          const isAffiliate = activeMembershipSubscription?.membershipKey === MembershipTypes.MembershipKeys.AFFILIATE;

          const allowedUpgradeTypesRef = response.block[0].cmsProducts.find(
            (product: any) => product.membershipKey === activeMembershipSubscription?.membershipKey
          )?.membershipUpgradeList;

          const allowedUpgradeTypes = response.block[0].cmsProducts.filter(
            (cmsProduct: any) =>
              allowedUpgradeTypesRef?.includes(cmsProduct.id) ||
              cmsProduct.membershipKey === activeMembershipSubscription?.membershipKey
          );

          if (isSubscribedToFlp && isAffiliate && isPERStatusCompleted) {
            const regularType = allowedUpgradeTypes?.filter(
              (membership: any) => membership?.membershipKey === MembershipTypes.MembershipKeys.REGULAR
            );
            return { list: regularType };
          }

          return { list: allowedUpgradeTypes };
        }

        const hasTypeExist = cmsProductResponse.find(
          (cmsProduct: { slug: string }) => cmsProduct.slug === preselectedType
        );
        if (hasTypeExist) {
          dispatch(setMembershipPackageType(hasTypeExist.id, hasTypeExist.slug));
        }

        return { list: cmsProductResponse, joiningFee };
      } catch (error) {
        return Promise.reject((error as any)?.response?.message);
      }
    }
);

export const setMembershipTypes: any = createAction(membershipActionNames.SET_MEMBERSHIP_TYPES);

export const setUpdateApprenticeLevel: any = createAction(
  membershipActionNames.SET_UPDATE_APPRENTICE_LEVEL,
  (isUpdateApprenticeLevel: boolean) => () => isUpdateApprenticeLevel
);

export const fetchMembershipTiers: any = createAction(
  membershipActionNames.GET_MEMBERSHIP_TIERS,
  (
      isCenterMembershipJourney: boolean = false,
      centerMembershipTypeSlug?: string,
      isFLP = false,
      isApprentice = false,
      tierCode?: string
    ) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      if (!isApprentice) dispatch(isLoading());
      const state = getState();
      const { membershipTypeSlug: credentialSlug } = membershipEligibleCredentialSelector(state);
      const slug = userMembershipTypeSelector(state);
      const membershipSlug = isCenterMembershipJourney
        ? centerMembershipTypeSlug
        : credentialSlug === slug
        ? credentialSlug
        : slug;
      // slugs must be lowercase
      const lowerCaseSlug = membershipSlug && membershipSlug.toLowerCase();

      const doesNotHaveSlugAndIsNotFLP = !lowerCaseSlug && !isFLP;

      if (doesNotHaveSlugAndIsNotFLP) {
        return null;
      }

      try {
        const response = await request(GET_MEMBERSHIP_SKUS_WITH_BENEFITS, { productSlug: lowerCaseSlug, isFLP });
        const membershipTiers = response.getMembershipTiers;
        // TODO: Will remove this once auto upgraded lambda is implemented
        if (tierCode && !isFLP) {
          const tierSku = membershipTiers?.variants.find((types: any) => types?.tierCode === tierCode)?.sku;
          await dispatch(setMembershipPackageTier(tierSku));
        }
        return membershipTiers;
      } catch (error) {
        return Promise.reject((error as any)?.response?.message || error);
      }
    }
);

export const addMembershipToCart: any = createAction(
  membershipActionNames.ADD_MEMBERSHIP_TO_CART,
  async (isCenterMembershipRenewalJourney: boolean = false, centerMembershipEndDate: string) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      dispatch(isLoading());
      const state = getState();
      // promo code from the URL params
      const promoCode = getMembershipCode(state);
      // promo code applied outside addMembershipToCart
      const appliedPromoCode = appliedPromoCodeSelector(state);
      const activeMembershipSubscription = activeMembershipSubscriptionSelector(state);
      const userChoiceData = userMembershipPackageSelector(state);
      const sectionsCredentialsRenewal = sectionsCredentialsRenewalSelector(state);
      const { list: relatedPathwayProductList } = membershipRelatedPathwaysSelector(state);
      const personAccountData = personAccountDataSelector(state);

      const learningPathway = learningPathwaySelector(state);
      const currentLearningPathway = currentJourneyLearningPathwaySelector(state);
      const activeCimaMem = isCimaMemberSelector(state);
      const selectedMembershipProduct = membershipProductSelector(state);
      const cimaMembershipType = cimaMembershipsTermTypeSelector(state);
      const currentCimaSelectedMembershipKey = selectedCimaMembershipKeyByUserChoiceSelector(state);

      const isCimaRenewalSeason = isCimaRenewalSeasonSelector(state);
      const isUserStudying = isUserStudyingSelector(state);
      const isUserHasMou = isConditionalExemptionStudentSelector(state);
      const isFirmAffiliatedForCPEAOrPCPS = isFirmAffiliatedForCPEAOrPCPSSelector(state);
      const inactiveMembership = customerInactiveMembershipsSelector(state);
      const inactiveCredential = customerInactiveCredentialsSelector(state);
      const inactiveMembershipsTermType = inactiveMembershipsTermTypeSelector(state);
      const inactiveMembershipsBody = inactiveMembershipsBodySelector(state);
      const isUserMemberLapsed = isUserMemberLapsedSelector(state);
      const isAicpaMemberFiveYearsLapsed = isAicpaMemberFiveYearsLapsedSelector(state);
      const isAicpaMemberLapsed = isAicpaMemberLapsedSelector(state);
      const useRemoveProrationFeature = getFeatureToggleByKeySelector(state, USE_CR_723_REMOVE_PRORATION);
      const membershipEvent = membershipEventSelector(state);

      const selectedExemption = selectedExemptionSelector(state);
      const isFLPSwitch = isFLPSwitchSelector(state);
      const isFLPUpgrade = isFLPUpgradeSelector(state);
      const exemptionPromocode = appliedPromoCodeCustomExemptionFields(state);
      const isGiveawayExempPromocode: boolean =
        Boolean(exemptionPromocode.promocode) && Object.keys(exemptionPromocode.exemptionGiveaway).length !== 0;
      const giveExempLevelObj: any = isGiveawayExempPromocode ? exemptionPromocode.exemptionGiveaway : [];

      const isFlpSwitchOrUpgrade = Boolean(isFLPSwitch || isFLPUpgrade);
      const isLapsedFCMA = inactiveCredential?.[0]?.credentialTerms?.[0]?.credentialKey === Product.CredentialKey.FCMA;

      const perStatus = practicalExperienceRequirementStatusSelector(state);
      const isPERStatusCompleted = perStatus === MembershipTypes.PERPortfolioStatus.COMPLETED;
      const productListDataHasFLP = flpProductListDataSelector(state);
      const cartId = cartIdSelector(state);
      const cartVersion = cartVersionSelector(state);

      const isMembershipBodyCIMA =
        selectedMembershipProduct?.membershipBody?.key === Product.ProductMembershipBody.CIMA;
      const isMembershipKeyCandidate =
        selectedMembershipProduct?.membershipKey === MembershipTypes.MembershipKeys.CANDIDATE;
      const isL7CandidateUpgrade = isL7CandidateUpgradeSelector(state);

      const isCimaMembershipZeroPrice =
        !isUserMemberLapsed &&
        currentLearningPathway !== MembershipTypes.Pathway.FLP &&
        learningPathway !== MembershipTypes.Pathway.FLP &&
        // 1. Signup: Free candidate membership for 1 yr
        ((!activeCimaMem && currentCimaSelectedMembershipKey === MembershipTypes.MembershipKeys.CANDIDATE) ||
          // 2. MOU Functionality
          // Free candidate membership for 1 yr if the user is still studying during signup. Will be overriden by #1
          // Free candidate membership until the user graduates only if the user is:
          //  a. still studying
          //  b. conditional exemption student
          (activeCimaMem &&
            isCimaRenewalSeason &&
            isUserStudying &&
            isUserHasMou &&
            cimaMembershipType === MembershipTypes.MembershipKeys.CANDIDATE));

      // To check if tierCode is Core - for Mandatory Exam Credit inclusion
      const isTierCodeCore = selectedMembershipProduct?.variants?.find(
        (variant: any) => variant?.tierCode === MembershipTypes.TierCode.CORE
      );

      // flag to determine if user came from FCMA prop page
      const isFromFcmaPropPage = clickedFcmaPropPageSelector(state);
      // flag to check if user has FCMA credential purchased
      const isUserAlreadyPurchasedFcma = hasFcmaCredentialProductSelector(state);
      // flag to check if user has 10 years regular membership
      const isFcmaRouteOne = cimaMembershipTermIsTenYearsSelector(state);
      const isRenewalsJourney = isRenewalsJourneySelector(state);

      // CR682 - Here is where we add the FCMA to cart
      const useCR682FcmaRenewalEnhancement = getFeatureToggleByKeySelector(state, USE_CR_682);
      const isAddFcmaCredentialInCart = isAddFcmaCredentialInCartSelector(state);
      let fcmaCredentialProduct = fcmaCredentialProductSelector(state);
      const isAllowToAddFcmaInCart = isAllowToAddFcmaInCartSelector(state);

      if (areAllTruthy(useCR682FcmaRenewalEnhancement, isAddFcmaCredentialInCart, isAllowToAddFcmaInCart)) {
        setSessionStorageItem({ [StorageNames.isAllowToAddFcmaInCart]: true });

        let fcmaProduct = {
          productId: '',
          sku: '',
        };

        // Search for the FCMA product in the state first, if does not exist then get details from CommerceTools
        if (fcmaCredentialProduct) {
          fcmaProduct = {
            productId: fcmaCredentialProduct.productId,
            sku: Product.CIMA_CREDENTIALS_SKU.FCMA,
          };
        } else {
          const action = await dispatch(getProductBySku(Product.CIMA_CREDENTIALS_SKU.FCMA));
          fcmaCredentialProduct = action.payload;

          fcmaProduct = {
            productId: fcmaCredentialProduct!.productId,
            sku: Product.CIMA_CREDENTIALS_SKU.FCMA,
          };
        }

        userChoiceData.credentials = [fcmaProduct];
      }

      const userChoiceCredentialPayload = userChoiceData.credentials.map(
        (credential: { productId: string; sku: string }) => {
          return {
            productId: credential.productId,
            sku: credential.sku,
            ...(isFromFcmaPropPage &&
              !isUserAlreadyPurchasedFcma &&
              !isRenewalsJourney && { isCimaMembershipZeroPrice: true }),
          };
        }
      );

      userChoiceData.credentials = userChoiceCredentialPayload;

      let disableAutoAddOfMembershipFee = false;

      // Pathway selection
      if (userChoiceData.pathways.length) {
        // Remove Auto Adding of Fees for Membership
        disableAutoAddOfMembershipFee = true;

        const pathwayProductBundle = relatedPathwayProductList.find(product =>
          userChoiceData.pathways
            ?.map(choice => choice.sku)
            ?.includes?.(
              product?.bundleProducts?.find(bundle => bundle?.productType === Product.ProductType.MEMBERSHIP)
                ?.variants?.[0]?.sku as string // Bundle products contains only one variant this is already filtered out from the backend
            )
        );

        const getMembershipProductFromBundleProducts = pathwayProductBundle?.bundleProducts?.find(
          bundle => bundle?.variants?.[0]?.productType === Product.ProductType.MEMBERSHIP
        );

        if (getMembershipProductFromBundleProducts) {
          userChoiceData.type = {
            id: getMembershipProductFromBundleProducts.productId,
            slug: getMembershipProductFromBundleProducts.slug as string,
          };
          userChoiceData.tier = userChoiceData.pathways?.find(
            item => item?.productId === getMembershipProductFromBundleProducts?.productId
          )?.sku as string;

          userChoiceData.pathways = userChoiceData.pathways.filter(
            path => path.productId !== getMembershipProductFromBundleProducts.productId
          ); // remove membership type product
        }
        // FOR Delayed Benefit Groups - FCMA/ACMA
        // adding this to cart to provisioned FCMA Credential in customer account as part of regional bundle
        const getDelayedBenefitData = await request(GET_BENEFITS_BY_VARIANT_SKU, {
          sku: userChoiceData?.tier,
          isDelayedBenefit: true,
        });

        if (getDelayedBenefitData?.getBenefitsByVariantSku?.length) {
          const { productId, variants } = getDelayedBenefitData.getBenefitsByVariantSku[0];
          userChoiceData.credentials = [
            {
              productId,
              sku: variants[0].sku,
              isCimaMembershipZeroPrice: true,
            },
          ];
        }
      }

      let productFee = {
        slug: '',
        isCimaMembershipZeroPrice: false,
      };
      // Rejoining Fee for Lapsed Membership
      if ((isUserMemberLapsed && inactiveMembership?.length) || isLapsedFCMA) {
        productFee = {
          ...productFee,
          slug: getRejoiningFeeSlug(
            inactiveMembershipsBody,
            inactiveMembershipsTermType,
            learningPathway,
            isLapsedFCMA,
            isUserMemberLapsed,
            currentLearningPathway
          ),
        };

        // If Aicpa membership lapsed for 5 years CR
        if (areAllTruthy(isAicpaMemberFiveYearsLapsed, isAicpaMemberLapsed, useRemoveProrationFeature)) {
          productFee = {
            ...productFee,
            slug: Product.Fee.AICPA_JOINING_FEE,
          };
        }
      }
      // FCMA (FCMA Application Fee = 0)
      if (isFromFcmaPropPage) {
        productFee = { ...productFee, slug: Product.Fee.FCMA_APPLICATION_FEE };
        if (isFcmaRouteOne) {
          productFee = { ...productFee, isCimaMembershipZeroPrice: true };
        }
      }
      // L7 Candidate to Regular (CIMA Application Fee = 0)
      if (isL7CandidateUpgrade) {
        productFee = { slug: Product.Fee.CIMA_APPLICATION_FEE, isCimaMembershipZeroPrice: true };
      }
      if (productFee.slug) {
        userChoiceData.lateFeeProduct = {
          slug: productFee.slug,
          isCimaMembershipZeroPrice: productFee.isCimaMembershipZeroPrice,
        };
      }

      const usePQExemptionLevel = currentLearningPathway === MembershipTypes.Pathway.PQ &&
        isTierCodeCore &&
        isMembershipKeyCandidate &&
        isMembershipBodyCIMA &&
        selectedExemption && {
          pqExemptionLevel:
            ExemptionLevels.ExemptionLevels[
              Object.keys(ExemptionLevels.LevelName).find(
                key => (ExemptionLevels.LevelName as any)[key] === selectedExemption
              ) as any
            ],
        };

      // check if exemption promocode should override compulsory exam credit
      // sf term end date is delay for 1 day 2023-07-31 vs zuoraTermEndDate which is usually 2023-08-01.
      const formattedCenterMembershipEndDate = moment(centerMembershipEndDate).add(1, 'days').format('YYYY-MM-DD');
      const data = {
        ...userChoiceData,
        promoCode: appliedPromoCode || promoCode,
        activeMembershipSubscription,
        isCimaMembershipZeroPrice,
        isFirmAffiliatedForCPEAOrPCPS: isCenterMembershipRenewalJourney && isFirmAffiliatedForCPEAOrPCPS,
        ...usePQExemptionLevel,
        ...(isGiveawayExempPromocode && { promocodeExemptedExam: giveExempLevelObj }),
        disableAutoAddOfMembershipFee,
        isFlpSwitchOrUpgrade,
        isPERStatusCompleted,
        productListDataHasFLP,
        cartId,
        cartVersion,
        addCpaFlpProducts: false,
        sfPersonAccountId: personAccountData.id,
        isUserMemberLapsed,
        isCenterMembershipRenewalJourney,
        centerMembershipEndDate: formattedCenterMembershipEndDate,
      };

      if (
        !sectionsCredentialsRenewal.isTriggered &&
        !data.type.id &&
        !activeMembershipSubscription?.productId &&
        !isFromFcmaPropPage
      ) {
        return Promise.reject('No membership type selected.');
      }

      if (
        !sectionsCredentialsRenewal.isTriggered &&
        !data.tier &&
        !activeMembershipSubscription?.productId &&
        !isFromFcmaPropPage
      ) {
        return Promise.reject('No membership tier selected.');
      }
      await dispatch(setAddCartLoading(true));

      const inviteData = membershipInviteDataSelector(state);

      if (inviteData?.inviteId) {
        const customHeaders = {
          existingEntity: inviteData?.organization?.organizationCapabilities?.type?.toLowerCase() || 'association',
          currency: {
            label: inviteData?.organization?.currency || Product.ProductCurrencyLabel.USD,
          },
        };
        await updateContext(customHeaders);
      }

      const userHasBeenLicensedBefore = userHasBeenLicensedBeforeSelector(state);
      if (userHasBeenLicensedBefore) {
        const isUserRegularAicpaMember = isUserRegularAicpaMemberSelector(state);
        if (!isUserRegularAicpaMember) {
          data.addCpaFlpProducts = true;
        }
      }
      // add the membership product
      const currency = productCurrencySelector(state);
      await updateContext({
        currency: {
          label: currency?.label || 'USD',
        },
      });
      const cart = await request(ADD_MEMBERSHIP_TO_CART, { data });
      await dispatch(setAddCartLoading(false));

      const { pathname } = getLocation(state as any);
      const isRouteCheckout: boolean =
        pathname === generatePath(getPath(Routes.CHECKOUT_PAGE)) ||
        Boolean(matchPath(pathname, { path: getPath(Routes.PAYPAL_PAYMENT_CHECKOUT_PROCEED), exact: true }));

      if (isFlpSwitchOrUpgrade && !isRouteCheckout) {
        dispatch(resetSalesTax());
      }

      if (cart.addMembershipToCart) {
        // Store state for this cart, in case it gets cleared by a refresh during checkout
        const cartState: Cart.ActiveCart = {
          cartId: cart.addMembershipToCart.id,
          isClickedMembershipUpgrade: membershipEvent?.isClickedMembershipUpgrade ?? false,
          isPERCompleted: isPERStatusCompleted,
        };
        setLocalStorageItem({[StorageNames.activeCart]: cartState});

        await dispatch(setCart(cart.addMembershipToCart));
        return cart;
      }

      return;
    }
);

export const fetchMembershipRelatedAddons: any = createAction(
  membershipActionNames.GET_MEMBERSHIP_RELATED_ADDONS,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(isLoading());
    const state = getState();
    const { membershipTypeSlug: credentialSlug } = membershipEligibleCredentialSelector(state);
    const { membershipTypeSlug: sectionSlug } = membershipEligibleSectionSelector(state);
    const currentMembershipSubscription = currentMembershipSubscriptionSelector(state); // if coming from sections/credentials renewal
    const currentProducts = productsListDataLineItemsSelector(state);
    const exemptionLevelEntry = exemptionLevelEntrySelector(state);
    const isCimaMembershipJourney = isCimaMembershipJourneySelector(state);
    const qualificationLevels = qualificationLevelsSelector(state);
    const selectedExemptReferenceLevel = selectedExemptReferenceLevelSelector(state);
    const personChosenExemptionLevel = personChosenExemptionLevelSelector(state);
    const userChoice = userMembershipPackageSelector(state);
    const isExpressRenewal = isExpressRenewalSelector(state);

    const isAllExamsPassed = qualificationLevels?.every((level: QualificationLevel) =>
      [UserSchema.QualificationLevelStatus.Completed, UserSchema.QualificationLevelStatus.Exempted].includes(
        level.status
      )
    );
    const isUsingCimaEntryLevel = [
      isCimaMembershipJourney,
      !isAllExamsPassed,
      !!(exemptionLevelEntry?.name ?? personChosenExemptionLevel),
    ].every((flag: boolean) => flag);

    const activeSkus = currentProducts?.map(prod => prod?.variant?.sku) || [];
    const activeNotFreeSkus =
      currentProducts?.filter(item => !!item.totalPrice?.centAmount).map(item => item.variant?.sku) || [];
    const variantSku =
      userMembershipTierSelector(state) || credentialSlug || sectionSlug || currentMembershipSubscription?.variant?.sku;

    let getAddonsByVariantSku: State.AddOnProduct[];
    const cimaEntryLevel = MembershipTypes.FLPLevels.find(
      (level: { key: string }) => level.key === (exemptionLevelEntry?.name || personChosenExemptionLevel)
    )?.value;

    try {
      const getAddonsByVariantSkuReq = await request(GET_ADDONS_BY_VARIANT_SKU, {
        memberTypeTierSkuPrefix: variantSku,
        cimaEntryLevel: isUsingCimaEntryLevel ? selectedExemptReferenceLevel || cimaEntryLevel : '',
      });
      getAddonsByVariantSku = getAddonsByVariantSkuReq.getAddonsByVariantSku;
    } catch (e) {
      getAddonsByVariantSku = [];
    }

    const addOnsByVariantSku: State.AddOnProduct[] = getAddonsByVariantSku;

    const list = addOnsByVariantSku
      .map((data: State.AddOnProduct) => {
        const isIncluded = shouldAddOnProductBeIncluded(userChoice.tier, data.variants[0].sku);

        return {
          ...data,
          isIncluded,
        };
      })
      .filter((addon: State.AddOnProduct) =>
        addon.productType !== Product.ProductType.EXAM_CREDIT
          ? isExpressRenewal
            ? !activeNotFreeSkus.includes(addon.variants[0].sku)
            : !activeSkus.includes(addon.variants[0].sku)
          : true
      );

    const setOfCategories = addOnsByVariantSku.reduce((agg, data: State.AddOnProduct) => {
      if (data.categories) {
        data.categories.forEach(category => {
          agg.add(category.name);
        });
      }
      return agg;
    }, new Set());

    const categories = Array.from(setOfCategories)
      .map(categoryName => {
        return {
          name: categoryName,
          selected: false,
          page: 1,
        };
      })
      .concat({ name: 'All', selected: true, page: 1 })
      .reverse();

    return {
      list: !!list.length ? list : [],
      categories: !!categories.length ? categories : [],
    };
  }
);

interface Attribute {
  name: string;
  value: Array<{ [x: string]: string }>;
}

export const fetchMembershipSections: any = createAction(
  membershipActionNames.GET_MEMBERSHIP_SECTIONS,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(isLoading());
    let benefitQuantity = 0;
    const state = getState();
    const { membershipTypeSlug: credentialSlug } = membershipEligibleCredentialSelector(state);
    const variantSku = userMembershipTierSelector(state) || credentialSlug;
    const existingSections = existingSectionSelector(state);
    const sectionsCredentialsRenewal = sectionsCredentialsRenewalSelector(state);

    const useNewMembershipAicpa = getFeatureToggleByKeySelector(state, USE_NEW_MEMBERSHIP_AICPA);
    const isRenewalsJourney = isRenewalsJourneySelector(state);

    let listOfSectionFreeProduct: State.SectionFreeProduct[] = [];
    let listOfSectionProductWithPrice: State.SectionWithPriceProduct[] = [];

    const { getProductsByType } = await request(GET_PRODUCTS_BY_TYPE_FLAT_CARDS, {
      type: Product.ProductType.SECTION,
    });

    const locale = 'en-US';
    let bG: string;
    getProductsByType.forEach((data: Product.ProductSection) => {
      if (data.includedInBenefit?.value.length > 0) {
        data.includedInBenefit.value.forEach((benefitGroup: any) => {
          if (!bG) {
            const benefitPartOf = benefitGroup.obj.masterData.current.masterVariant.attributes.find(
              (a: Attribute) => a.name === 'benefitListPartOf'
            );

            // Scenario: buying of NFP section in landing page (Active Mem: AICPA Regular - PC; Prev Mem: AICPA Regular - PS)
            // setMembershipPackageTier in checkSelectedSection action is looping membership-type products in PLD
            // variantSku comes from userChoice.tier which is prev sku causing issues in Section UI

            const matchingBenefitGroup = benefitPartOf?.value.find((tier: any) => tier[locale] === variantSku);
            if (!!matchingBenefitGroup) {
              // we found a benefit group which is included in our tier
              bG = matchingBenefitGroup[locale];
              benefitQuantity = Number(
                benefitGroup.obj.masterData.current.masterVariant.attributes
                  .find((a: Attribute) => a.name === 'benefitQuantity')
                  ?.value.toString()
              );
            }
          }
        });
      }
    });
    getProductsByType.forEach((data: Product.ProductSection) => {
      const isExistInExistingSections = existingSections.some(
        (existingSection: any) => existingSection.productId === data.productId
      );
      if (sectionsCredentialsRenewal.isTriggered && isExistInExistingSections) {
        listOfSectionProductWithPrice = [...listOfSectionProductWithPrice, data];
        return;
      }
      data.includedInBenefit?.value.forEach((benefitGroup: any) => {
        if (
          !!bG &&
          benefitGroup.obj.masterData.current.masterVariant.attributes
            .find((a: Attribute) => a.name === 'benefitListPartOf')
            ?.value.some((attr: any) => attr[locale] === bG)
        ) {
          listOfSectionFreeProduct = [
            ...listOfSectionFreeProduct,
            {
              ...data,
              includedInBenefit: {
                ...data.includedInBenefit,
                value: [benefitGroup],
              },
            },
          ];
        }
      });
      listOfSectionProductWithPrice = [...listOfSectionProductWithPrice, data];
    });

    const sectionFreeProducts = listOfSectionFreeProduct.map((data: State.SectionFreeProduct) => ({
      ...data,
      enabled: true,
    }));

    const sectionProductWithPrice = listOfSectionProductWithPrice.map((data: State.SectionWithPriceProduct) => ({
      ...data,
    }));

    const setOfCategories = listOfSectionProductWithPrice.reduce((agg, data: State.AddOnProduct) => {
      if (data.categories) {
        data.categories.forEach(category => {
          agg.add(category.name);
        });
      }
      return agg;
    }, new Set());

    const categories = Array.from(setOfCategories)
      .map(categoryName => {
        return {
          name: categoryName,
          selected: false,
          page: 1,
        };
      })
      .concat({ name: 'All', selected: true, page: 1 })
      .reverse();

    if (isRenewalsJourney && useNewMembershipAicpa) {
      dispatch(isNotLoading());
    }

    return {
      freeProduct: !!sectionFreeProducts.length ? sectionFreeProducts : [],
      productWithPrice: !!sectionProductWithPrice.length ? sectionProductWithPrice : [],
      benefitQuantity,
      categories: !!categories.length ? categories : [],
    };
  }
);

export const fetchMembershipCredentials: any = createAction(
  membershipActionNames.GET_MEMBERSHIP_CREDENTIALS,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(isLoading());
    const state = getState();
    const { membershipTypeSlug: credentialSlug } = membershipEligibleCredentialSelector(state);
    const variantSku = userMembershipTierSelector(state) || credentialSlug;
    const sectionsCredentialsRenewal = sectionsCredentialsRenewalSelector(state);
    const existingCredentials = existingCredentialSelector(state);
    const membershipEvent = membershipEventSelector(state);
    const comingFromPropPage = isComingFromPropPageSelector(state);
    const credentialJourney = isCredentialsJourneySelector(state);
    const useNewMembershipAicpa = getFeatureToggleByKeySelector(state, USE_NEW_MEMBERSHIP_AICPA);
    const isRenewalsJourney = isRenewalsJourneySelector(state);

    const { getProductsByType } = await request(GET_PRODUCTS_BY_TYPE, {
      type: Product.ProductType.CREDENTIAL,
    });
    const credentials: State.ProductCredential[] = getProductsByType;
    let tierIncludedCredential: State.ProductCredential[] = [];
    const membershipTermType = state?.user.memberships?.[0]?.membershipTerm?.membershipTermType; // non member must not be undefined

    const membershipKey = state.membership?.product?.membershipKey || null; // cannot destructure since the object product is expecting null

    const getMembershipType =
      membershipKey || // selection of user on package builder
      membershipTermType || // use for credential prop page
      MembershipTypes.MembershipKeys.REGULAR; // show all credentials

    credentials.forEach((credential: State.ProductCredential) => {
      if (!!credential.tiersAvailable?.length) {
        const hasSameTierAvailable = credential.tiersAvailable.find(
          (available: any) => available['en-US'] === variantSku
        );
        const isExistInExistingCredentials = existingCredentials.some(
          (existingCredential: any) => existingCredential.productId === credential.productId
        );
        if (
          (sectionsCredentialsRenewal.isTriggered && isExistInExistingCredentials) ||
          (hasSameTierAvailable &&
            !tierIncludedCredential.some(tierIncludedCred => tierIncludedCred.productId === credential.productId)) ||
          (membershipEvent.isClickedMembershipUpgrade && isExistInExistingCredentials) ||
          comingFromPropPage ||
          credentialJourney ||
          (useNewMembershipAicpa && isRenewalsJourney)
        ) {
          credential.variants.forEach(element => {
            const { membershipTypes = [] } = element;

            const isEligible = membershipTypes.length
              ? membershipTypes.some(
                  (membership: any) =>
                    membership.label['en-US'].toLowerCase() === getMembershipType?.toLowerCase() ||
                    membership.key.toLowerCase() === getMembershipType?.toLowerCase()
                )
              : false;

            if (isEligible) {
              tierIncludedCredential = [...tierIncludedCredential, credential];
              tierIncludedCredential = [...new Set(tierIncludedCredential)];
            }
          });
        }
      }
    });

    const setOfCategories = tierIncludedCredential.reduce((agg, credential: State.ProductCredential) => {
      if (credential.categories) {
        credential.categories.forEach(category => {
          agg.add(category.name);
        });
      }
      return agg;
    }, new Set());

    const categories = Array.from(setOfCategories)
      .map(categoryName => {
        return {
          name: categoryName,
          selected: false,
          page: 1,
        };
      })
      .concat({ name: 'All', selected: true, page: 1 })
      .reverse();

    return {
      list: !!tierIncludedCredential.length ? tierIncludedCredential : [],
      categories: !!categories.length ? categories : [],
    };
  }
);

export const showMembershipFirstPageOfAddOnProducts: any = createAction(
  membershipActionNames.SHOW_MEMBERSHIP_FIRST_PAGE_OF_ADDON_PRODUCTS,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    await dispatch(fetchMembershipRelatedAddons());
    const state = getState();
    const firstPageOfAddonProducts = membershipFirstPageOfAddOnProductsSelector(state);
    return firstPageOfAddonProducts;
  }
);

export const getMembershipAddOnProducts: any = createAction(
  membershipActionNames.ADD_ADDON_PRODUCT_LIST_DATA_IN_USERCHOICE,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    await dispatch(showMembershipFirstPageOfAddOnProducts());

    const state = getState();
    const productListData = membershipSubscriptionsSelector(state);
    const relatedAddOnProductList: State.AddOnProducts[] = membershipRelatedAddonsListSelector(state);
    const membershipSubscriptionsFetched = membershipSubscriptionsFetchedSelector(state);
    const isAddonsFetched = membershipAddonsFetchedSelector(state);
    const isRenewal = isRenewalSelector(state);

    if (membershipSubscriptionsFetched && relatedAddOnProductList.length && isRenewal && isAddonsFetched) {
      productListData?.lineItems?.forEach((lineItem: State.LineItem) => {
        const isOnAddOns: any = relatedAddOnProductList.find(
          addOn => lineItem?.variant?.sku === addOn?.variants?.[0]?.sku
        );
        if (isOnAddOns) {
          return dispatch(addMembershipPackageRelatedAddon(lineItem.productId, lineItem.variant?.sku));
        }
      });
    }
  }
);

export const showMembershipFirstPageOfCredentials: any = createAction(
  membershipActionNames.SHOW_MEMBERSHIP_FIRST_PAGE_OF_CREDENTIAL_PRODUCTS,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    await dispatch(fetchMembershipCredentials());
    const state = getState();
    const firstPageOfCredentialProducts = membershipFirstPageOfCredentialProductsSelector(state);

    return firstPageOfCredentialProducts;
  }
);

export const filterMembershipRelatedAddonByCategory: any = createAction(
  membershipActionNames.FILTER_MEMBERSHIP_PACKAGE_RELATED_ADDON_BY_CATEGORY,
  (categoryName: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const { updateSelectedCategory, filteredProducts } =
      membershipFilterRelatedAddOnByCategorySelector(categoryName)(state);

    return {
      updateSelectedCategory,
      filteredProducts,
    };
  }
);

export const filterMembershipRelatedPathwaysByCategory: any = createAction(
  membershipActionNames.FILTER_MEMBERSHIP_PACKAGE_RELATED_PATHWAY_BY_CATEGORY,
  (categoryName: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const { updateSelectedCategory, filteredProducts } =
      membershipFilterRelatedPathwaysByCategorySelector(categoryName)(state);

    return {
      updateSelectedCategory,
      filteredProducts,
    };
  }
);

export const filterMembershipCredentialByCategory: any = createAction(
  membershipActionNames.FILTER_MEMBERSHIP_CREDENTIAL_BY_CATEGORY,
  (categoryName: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const { updateSelectedCategory, filteredProducts } =
      membershipFilterCredentialByCategorySelector(categoryName)(state);

    return {
      updateSelectedCategory,
      filteredProducts,
    };
  }
);

/**
 * Assumption. If you are changing package type, then sections and addons should reset
 */

export const setMembershipPackageType: any = createAction(
  membershipActionNames.SET_MEMBERSHIP_PACKAGE_TYPE,
  async (
      id: string,
      slug: string,
      isCimaMembershipJourney: boolean = false,
      resetTier = true,
      retainAddOns: boolean = false
    ) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state = getState();
      const membershipPropPageURL = membershipPropPageURLSelector(state);
      const userChoice = userMembershipPackageSelector(state);

      if (isCimaMembershipJourney) return { id, slug, resetTier, retainAddOns };
      // If this action is called and the same id and slug is found. Retain their sections and addons
      if (userChoice.type.id === id && userChoice.type.slug === slug) {
        return { id, slug, resetTier: false, retainAddOns: true };
      }

      let shouldRemoveSection = true;

      // Don't remove preselected section if it is from prop page
      if (membershipPropPageURL?.section) {
        shouldRemoveSection = false;
      }

      const landingPage = staticLandingPageMainColumnSelector(state);
      const landingPageMembershipSubBlock = landingPage?.find(
        (item: any) => item?.contentType === ContentfulHelpers.BLOCK_TYPES.MEMBERSHIP_PRODUCT_BLOCK
      );

      const list = (landingPageMembershipSubBlock as any)?.cmsProducts;
      if (list?.length) {
        dispatch(setMembershipTypes(list));
      }

      return { id, slug, shouldRemoveSection, retainAddOns };
    }
);

export const setMembershipPackageTier: any = createAction(
  membershipActionNames.SET_MEMBERSHIP_PACKAGE_TIER,
  (sku: string, isFLPProductType = false, retainAddOns: boolean = false) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state = getState();
      const isCimaMembershipsJourney = isCimaMembershipJourneySelector(state);
      const userMembershipTier = userMembershipTierSelector(state);
      const selectedExemption = selectedExemptionSelector(state);
      const prevSelectedExemptReference = prevSelectedExemptReferenceSelector(state);
      const userChoice = userMembershipPackageSelector(state);
      const isRenewalsJourney = isRenewalsJourneySelector(state);
      const useNewMembershipAicpa = getFeatureToggleByKeySelector(state, USE_NEW_MEMBERSHIP_AICPA);

      const shouldRetainFreeProducts = isRenewalsJourney && useNewMembershipAicpa;
      // If this action is called and the same sku is found. Retain their addons
      if (userChoice.tier === sku && !isFLPProductType) {
        return { sku, isRetainAddons: true, shouldRetainFreeProducts };
      }

      const isRetainAddons =
        isCimaMembershipsJourney && sku === userMembershipTier && selectedExemption === prevSelectedExemptReference
          ? true
          : retainAddOns;

      if (isFLPProductType) {
        const membershipFLPProducts = membershipFLPProductsSelector(state);
        const selectedFLPProduct = membershipFLPProducts.find((flpProducts: any) =>
          flpProducts.variants.otherVariants.find((variant: any) => variant.sku === sku)
        );
        if (selectedExemption) {
          dispatch(setPrevSelectedExemptReference(selectedExemption));
        }
        if (selectedFLPProduct) {
          const isCimaMembershipJourney = true;
          const resetTier = false;
          dispatch(
            setMembershipPackageType(
              selectedFLPProduct.productId,
              selectedFLPProduct.slug,
              isCimaMembershipJourney,
              resetTier,
              isRetainAddons
            )
          );
        }
      }
      return { sku, isRetainAddons };
    }
);

export const addMembershipPackageRelatedAddon: any = createAction(
  membershipActionNames.ADD_MEMBERSHIP_PACKAGE_RELATED_ADDON,
  (productId: string, sku: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    return {
      productId,
      sku,
    };
  }
);

export const removeMembershipPackageRelatedAddon: any = createAction(
  membershipActionNames.REMOVE_MEMBERSHIP_PACKAGE_RELATED_ADDON,
  (productId?: string) => () => productId
);

export const addMembershipPackageCredential: any = createAction(
  membershipActionNames.ADD_MEMBERSHIP_PACKAGE_CREDENTIAL,
  (
      productId: string,
      sku: string,
      numOfExams?: string,
      eligibleExams?: any[],
      pathwayName?: string,
      alternativeExams?: any[],
      credentialKey?: MembershipTypes.CredentialKeys
    ) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      return {
        num_of_exams: numOfExams,
        eligibleExams,
        pathway_name: pathwayName,
        productId,
        sku,
        alternativeExams,
        credentialKey,
      };
    }
);

export const setCredentialApplicationValue: any = createAction(
  membershipActionNames.SET_CREDENTIAL_APPLICATION_VALUE,
  (fieldName: any, value: any) => () => {
    return { [fieldName]: value };
  }
);

export const submitCredentialApplication: any = createAction(
  membershipActionNames.SUBMIT_CREDENTIAL_APPLICATION,
  async (productSku: string, formData: Partial<State.Membership['credentialQuestions']>) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state = getState();
      const cq = membershipCredentialApplicationSelector(state);
      const { id } = applicationSelector(state);
      const credentials = cartCredentialsSelector(state);
      const credentialAppParts = credentialApplicationPartSelectors(state);
      const cred = credentials.find(credential => credential.variant?.sku === productSku);
      const productId = cred?.variant?.sku;
      if (cred?.variant?.attributes.credentialKey === undefined || cred?.variant?.attributes.pathwayKey === undefined) {
        return false;
      }
      const credentialKey = MembershipTypes.generateCredentialKey(
        cred?.variant?.attributes.credentialKey?.key as MembershipTypes.CredentialKeys,
        cred?.variant?.attributes.pathwayKey?.key
      );
      const { id: applicationPartId } =
        credentialAppParts.find(({ productKeyId }) => productKeyId === productSku) || {};
      const credentialQuestions = formData || cq;

      // TODO: Add typing?
      // TODO: Handle multiple credentials
      const applicationInfo = {
        id,
        applicationPart: [
          {
            applicationPartId,
            applicationKey: id,
            credential: productId,
            credentialKey,
            productSku,
            credentialQuestions,
          },
        ],
      };

      // TODO: Add mutation
      const response = await request(UPDATE_APPLICATION_CREDENTIALS, { applicationInfo });

      if (!response?.updateApplicationCredentials) {
        // TODO
        console.error('Cannot update application credential');
      }
      return response?.updateApplicationCredentials;
    }
);

export const removeMembershipPackageCredential: any = createAction(
  membershipActionNames.REMOVE_MEMBERSHIP_PACKAGE_CREDENTIAL,
  (productId: string) => () => productId
);

export const removeMembershipPackageCredentialBySku: any = createAction(
  membershipActionNames.REMOVE_MEMBERSHIP_PACKAGE_CREDENTIAL_BY_SKU,
  (sku: string) => () => sku
);
export const resetUserChoiceSlug: any = createAction(
  membershipActionNames.RESET_MEMBERSHIP_USER_CHOICESLUG,
  (type: string) => {
    return { type };
  }
);

export const resetUserChoice: any = createAction(membershipActionNames.RESET_MEMBERSHIP_USER_CHOICE);

// sectionProductWithPrice
export const addMembershipPackageSectionFreeProduct: any = createAction(
  membershipActionNames.ADD_MEMBERSHIP_PACKAGE_SECTION_FREE_PRODUCT,
  (args: { productId: string; sku: string; group: string; groupSku: string; benefitName: string }) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const { productId, sku, group, groupSku, benefitName } = args;

      dispatch(removeMembershipPackageSectionWithPriceProduct(sku));
      return {
        productId,
        sku,
        group,
        groupSku,
        benefitName,
      };
    }
);

export const removeMembershipPackageSectionFreeProduct: any = createAction(
  membershipActionNames.REMOVE_MEMBERSHIP_PACKAGE_SECTION_FREE_PRODUCT,
  (productId: string) => () => productId
);

export const addMembershipPackageSectionWithPriceProduct: any = createAction(
  membershipActionNames.ADD_MEMBERSHIP_PACKAGE_SECTION_WITH_PRICE_PRODUCT,
  (productId: string, sku: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    return {
      productId,
      sku,
    };
  }
);

export const removeMembershipPackageSectionWithPriceProduct: any = createAction(
  membershipActionNames.REMOVE_MEMBERSHIP_PACKAGE_SECTION_WITH_PRICE_PRODUCT,
  (productId: string) => () => productId
);

export const membershipPackageUpdatePageOfSpecificAddonCategory: any = createAction(
  membershipActionNames.MEMBERSHIP_PACKAGE_UPDATE_PAGE_OF_SPECIFIC_ADDON_CATEGORY,
  (categoryName: string) => categoryName
);

export const membershipPackageUpdatePageOfSpecificPathwayCategory: any = createAction(
  membershipActionNames.MEMBERSHIP_PACKAGE_UPDATE_PAGE_OF_SPECIFIC_PATHWAY_CATEGORY,
  (categoryName: string) => categoryName
);

export const membershipPackageUpdatePageOfSpecificCredentialCategory: any = createAction(
  membershipActionNames.MEMBERSHIP_PACKAGE_UPDATE_PAGE_OF_SPECIFIC_CREDENTIAL_CATEGORY,
  (categoryName: string) => categoryName
);

export const seeMoreMembershipCredentialProduct: any = createAction(
  membershipActionNames.SEE_MORE_MEMBERSHIP_PACKAGE_CREDENTIAL_PRODUCT,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const moreRelatedCredentialProduct = membershipSeeMoreCredentialSelector(state);

    return moreRelatedCredentialProduct;
  }
);

export const seeMoreMembershipRelatedAddonProduct: any = createAction(
  membershipActionNames.SEE_MORE_MEMBERSHIP_PACKAGE_RELATED_ADDON_PRODUCT,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const moreRelatedAddOnProduct = membershipSeeMoreRelatedAddOnSelector(state);

    return moreRelatedAddOnProduct;
  }
);

export const seeMoreMembershipRelatePathwayProduct: any = createAction(
  membershipActionNames.SEE_MORE_MEMBERSHIP_PACKAGE_RELATED_PATHWAY_PRODUCT,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const moreRelatedPathwayProduct = membershipSeeMoreRelatedPathwaySelector(state);

    return moreRelatedPathwayProduct;
  }
);

export const seeMoreMembershipSectionProductWithPrice: any = createAction(
  membershipActionNames.SEE_MORE_MEMBERSHIP_PACKAGE_SECTION_PRODUCT_WITH_PRICE,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const moreSectionProduct = membershipSeeMoreSectionSelector(state);

    return moreSectionProduct;
  }
);

export const setCenterMembershipPackageType: any = createAction(
  membershipActionNames.SET_CENTER_MEMBERSHIP_PACKAGE_TYPE,
  (id: string, slug: string) => ({ id, slug: slug.toLowerCase() })
);

export const getMembershipBenefits: any = createAction(
  membershipActionNames.GET_MEMBERSHIP_BENEFIT,
  (type: Product.ProductType) =>
    (dispatch: Dispatch): Promise<void> => {
      return request(GET_PRODUCTS_BY_TYPE, { type })
        .then((response: any) => {
          if (response?.getProductsByType) {
            return { ...response, success: true };
          }
          return { success: false };
        })
        .catch(() => {
          return { success: false };
        });
    }
);

export const getUserSelectedBenefits: any = createAction(
  membershipActionNames.GET_SELECTED_BENEFITS,
  (SKUs: string[]) =>
    (dispatch: Dispatch): any => {
      if (!SKUs?.length) {
        return [];
      }

      const res = Promise.all(
        SKUs.map((sku: string) => {
          return request(GET_PRODUCT_ITEM, { slug: null, sku }).then((response: any) => {
            if (response.getProductBySlugOrSku) {
              return response.getProductBySlugOrSku;
            }
            return { success: false };
          });
        })
      );
      return res;
    }
);

export const getProducts: any = createAction(
  membershipActionNames.GET_MEMBERSHIP_BENEFIT_PRODUCT,
  (sku: string) => async (): Promise<void> => {
    return request(GET_PRODUCT_ITEM, { slug: null, sku }).then((response: any) => {
      if (response.getProductBySlugOrSku) {
        return { ...response, success: true };
      }
      return { success: false };
    });
  }
);

export const fetchMembershipRelatedBenefits: any = createAction(
  membershipActionNames.GET_MEMBERSHIP_RELATED_BENEFITS,
  async (sku: string, grouped: boolean = false) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state = getState();
      const useNewMembershipAicpa = getFeatureToggleByKeySelector(state, USE_NEW_MEMBERSHIP_AICPA);
      const isRenewalsJourney = isRenewalsJourneySelector(state);
      dispatch(isLoading());

      const { getBenefitsByVariantSku } = await request(GET_BENEFITS_BY_VARIANT_SKU, {
        sku,
        grouped,
      });
      if (getBenefitsByVariantSku) {
        if (useNewMembershipAicpa && isRenewalsJourney) {
          dispatch(isNotLoading());
        }

        const benefitByVariantSku: State.BenefitProduct[] = getBenefitsByVariantSku;
        return benefitByVariantSku;
      }

      if (useNewMembershipAicpa && isRenewalsJourney) {
        dispatch(isNotLoading());
      }

      return [];
    }
);

export const setMembershipJourneyType: any = createAction(
  membershipActionNames.SET_MEMBERSHIP_JOURNEY_TYPE,
  (journeyType: string): string => `${journeyType}`
);

export const getRenewalSeason: any = createAction(membershipActionNames.GET_RENEWAL_SEASON_INFO);

export const changeIndividualFilter: any = createAction(
  membershipActionNames.CHANGE_MEMBERSHIP_FILTER,
  (individualSearchObject: IndividualSearch) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const searchStr: string = `?${queryString.stringify(individualSearchObject, { arrayFormat: 'comma' })}`;
    const initialSearch = individualInitialSearchSelector(state);
    dispatch(
      push(
        generatePath(getPath(Routes.MEMBER_CREDENTIAL_DIRECTORY_RESULTS), {
          slug: searchStr.replace(/%/g, '%25'),
        })
      )
    );
    // If we are applying filters, keep the saved initial search state for when they are cleared
    if (!individualSearchObject.fromForm) {
      return { individualSearchObject: initialSearch ? initialSearch : individualSearchObject };
    }
    // If doing a new search from scratch, save the state in redux
    return { individualSearchObject };
  }
);

export const searchIndividual: any = createAction(
  membershipActionNames.SEARCH_INDIVIDUAL,
  (options: { useMerge: boolean } = { useMerge: false }) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const appState: State.Root = getState();
      const search = individualQuerySelector(appState);
      const isAdmin = isAdminPortalSelector(appState);
      const initialSearch = individualInitialSearchSelector(appState);
      let {
        fullName,
        firstName,
        lastName,
        city,
        state,
        within,
        zipCode,
        company,
        expertiseArea,
        membershipType,
        credential,
      }: any = parse(search, {
        arrayFormat: 'comma',
        parseNumbers: false,
      });

      const {
        searchType,
        country,
        pageNumber,
        pageSize = 12,
      }: any = parse(search, {
        arrayFormat: 'comma',
        parseNumbers: true,
      });

      // Refactor URL strings

      const fullyDecodeURI = (uri: string) => {
        let processedUri = uri;

        while (isEncoded(processedUri)) {
          processedUri = decodeURIComponent(processedUri);
        }

        return processedUri;
      };

      const isEncoded = (uri: string) => {
        const processedUri = uri || '';

        return processedUri !== decodeURIComponent(processedUri);
      };

      // decode URI / restore format from querystring for all input fields
      fullName = fullName ? fullyDecodeURI(fullName) : '';
      firstName = firstName ? fullyDecodeURI(firstName) : '';
      lastName = lastName ? fullyDecodeURI(lastName) : '';
      city = city ? fullyDecodeURI(city) : '';
      state = state ? fullyDecodeURI(state) : '';
      zipCode = zipCode ? fullyDecodeURI(zipCode) : '';
      company = company ? fullyDecodeURI(company) : '';
      credential = credential ? fullyDecodeURI(credential) : '';
      expertiseArea = expertiseArea ? fullyDecodeURI(expertiseArea) : '';
      membershipType = membershipType ? fullyDecodeURI(membershipType) : '';
      within = within ? within.toString() : '';

      const constants: any = constantsSelector(appState);

      const credentialProductSkuMap: any = {
        PFS: constants?.[CONSTANTS.CREDENTIAL_SKUS.PFS],
        MIP: constants?.[CONSTANTS.CREDENTIAL_SKUS.MIP],
        ACMA: constants?.[CONSTANTS.CREDENTIAL_SKUS.ACMA],
        CVFI: constants?.[CONSTANTS.CREDENTIAL_SKUS.CVFI],
        ABV: constants?.[CONSTANTS.CREDENTIAL_SKUS.ABV],
        CEIV: constants?.[CONSTANTS.CREDENTIAL_SKUS.CEIV],
        CGMA: constants?.[CONSTANTS.CREDENTIAL_SKUS.CGMA],
        FCMA: constants?.[CONSTANTS.CREDENTIAL_SKUS.FCMA],
        CFF: constants?.[CONSTANTS.CREDENTIAL_SKUS.CFF],
        CITP: constants?.[CONSTANTS.CREDENTIAL_SKUS.CITP],
      };

      return request(SEARCH_INDIVIDUAL, {
        searchType,
        fullName,
        firstName,
        lastName,
        location: { country, city, zipCode, state, within },
        membershipType,
        isAdmin,
        credential,
        company,
        expertiseArea,
        pageNumber,
        pageSize,
        credentialProductSkuMap,
      })
        .then((response: any) => {
          if (response?.searchIndividual) {
            return {
              ...response,
              success: true,
              searchType,
              loading: false,
              individualSearchObject: initialSearch,
            };
          }
          return { success: false, individualSearchObject: initialSearch };
        })
        .catch(() => {
          return { success: false, individualSearchObject: initialSearch };
        });
    }
);

interface ExtendedInvite extends State.MembershipInvite {
  productId: string;
  slug: string;
}

export const fetchDataOfSpecificInvite: any = createAction(
  membershipActionNames.GET_DATA_OF_SPECIFIC_INVITE,
  async (inviteId?: string, isCimaFirmInvite?: boolean) => async (dispatch: Dispatch, getState: () => State.Root) => {
    try {
      if (!isCimaFirmInvite) await dispatch(fetchMembershipTypes());
      const apiResponse = await request(GET_MEMBERSHIP_INVITE, { inviteId });

      const response: ExtendedInvite = apiResponse.getMembershipInvite;

      // if no inviteId found
      if (!response?.inviteId) {
        return { ...response };
      }

      const { getOrganizationByAccountNumberOrAccountId } = await request(QUERY_ORGANIZATION_BY_ACCOUNT_ID, {
        accountId: response?.organizationId,
      });

      if (response) {
        const { productId, slug, membershipTier } = response;

        const organization: State.Organization = getOrganizationByAccountNumberOrAccountId;
        if (!isCimaFirmInvite) await dispatch(fetchMembershipTypes());

        if (productId && membershipTier) {
          if (response.isPaidByFirm && response.isExistingUserUponInvite) {
            // update invite, skip application form, and redirect to thank you page
            dispatch(acceptFirmMembershipInvite(inviteId));
            dispatch(push(generatePath(getPath(Routes.INVITE_THANKYOU_PAGE))));
          }

          if (!isCimaFirmInvite) {
            await dispatch(setMembershipPackageType(productId, slug));
            await dispatch(setMembershipPackageTier(membershipTier));
          }

          if (productId && organization) {
            return {
              inviteId: response?.inviteId,
              isPaidByFirm: response.isPaidByFirm,
              type: { id: productId, slug },
              isExistingUserUponInvite: response.isExistingUserUponInvite,
              tier: membershipTier,
              organizationId: response?.organizationId,
              organization: {
                ...organization,
              },
              position: response?.position,
              status: response?.status,
              isFLP: response?.isFLP,
              isPaidByFLP: response?.isPaidByFLP,
              flpSubscriptionType: response?.flpSubscriptionType,
              duration: response?.duration,
            };
          }
        } else {
          // if not paid by firm
          return {
            isPaidByFirm: response?.isPaidByFirm,
            organizationId: response.organizationId,
            status: response.status,
            inviteId: response.inviteId,
            organization,
            position: response.position,
            isFLP: response?.isFLP,
            isPaidByFLP: response?.isPaidByFLP,
            flpSubscriptionType: response?.flpSubscriptionType,
            duration: response?.duration,
          };
        }
      }
    } catch (error) {
      const parsedError = JSON.parse(JSON.stringify(error));
      return { error: parsedError.response.errors[0].message };
    }
  }
);

// You are only allowed to add a credential which 'belongs' to a membership via CT attributes.
// Displaying this information in the UI for new joiners is relatively easy, but gets difficult for
// users who already have a membership, or if they are allowed to select a credential
// (or pathway - variant) while not logged in
export const checkIfCredentialEligibleForMembership: any = createAction(
  membershipActionNames.CHECK_IF_CREDENTIAL_ELIGIBLE_FOR_MEMBERSHIP,
  async (
      userMemberships: [{ type: string; tier: string; productSlug: string }],
      slug: string,
      productType: string,
      isVariant: boolean
    ) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state = getState();
      let payload = {};
      let variables = {};

      if (!!slug) {
        if (!isVariant && !!productType && productType === Product.ProductType.CREDENTIAL) {
          variables = {
            ...variables,
            slug,
            sku: null,
          };
        } else {
          if (isVariant) {
            variables = {
              ...variables,
              slug: null,
              sku: slug,
            };
          } else {
            variables = {
              ...variables,
              slug,
              sku: null,
            };
          }
        }

        // get credential product from CT
        const {
          getProductBySlugOrSku: { productId, tiersAvailable, variants, credentialKey },
        } = await request(GET_PRODUCT_ITEM, variables);

        const response = await request(
          QUERY_MEMBERSHIP_PRODUCT_BLOCK(membershipProductBlockSlug[Product.MembershipApplicationType.AICPA])
        );
        const cmsProductResponse = response.block?.[0]?.cmsProducts;

        if (!cmsProductResponse) {
          throw new Error('Membership Types not found.');
        }

        if (userMemberships && !!userMemberships.length) {
          // is existing user
          userMemberships.forEach(({ tier, productSlug: prodSlug }) => {
            if (!!tiersAvailable?.length) {
              const membershipTypeSlug = cmsProductResponse.find((cms: any) => cms.slug === prodSlug)?.slug;
              if (tiersAvailable.some((available: any) => available['en-US'] === tier)) {
                if (!!productType && productType === Product.ProductType.CREDENTIAL && !isVariant) {
                  // Credential is valid - let's add it to the user's choice!
                  payload = {
                    ...payload,
                    productId,
                    tierName: tier,
                    eligible: true,
                  };
                  const isComingFromPropPage = isComingFromPropPageSelector(state);
                  if (isComingFromPropPage) {
                    const masterVariantSku = variants.find((variant: Product.Variant) => variant.isMaster).sku;
                    dispatch(
                      addMembershipPackageCredential(
                        productId,
                        masterVariantSku,
                        '', // numOfExams
                        null, // eligibleExams
                        '', // pathwayName
                        null, // alternativeExams
                        credentialKey
                      )
                    );
                  }
                } else {
                  // Pathway is valid - let's add it to the user's choice!
                  payload = {
                    ...payload,
                    productId,
                    membershipTypeSlug,
                    tierName: tier,
                    eligible: true,
                  };
                }
              } else {
                // selection is not valid - throw some error to the user
                payload = {
                  ...payload,
                  membershipTypeSlug,
                  tierName: 'not eligible',
                  eligible: false,
                };
              }
            }
          });

          if (payload) return payload;
        }
      }
    }
);

export const checkSelectedSection: any = createAction(
  membershipActionNames.CHECK_SELECTED_SECTION_IF_IT_IS_PART_OF_MEMBERSHIP_BENEFIT,
  async (userMemberships: [{ type: string; tier: string; productSlug: string }], slug: string) =>
    async (dispatch: Dispatch) => {
      let payload = {};

      const { getProductsByType } = await request(GET_PRODUCTS_BY_TYPE_FLAT_CARDS, {
        type: Product.ProductType.SECTION,
      });

      const specificProduct = getProductsByType.find((product: Product.ProductSection) => product.slug === slug);

      const cmsResponse = await request(
        QUERY_MEMBERSHIP_PRODUCT_BLOCK(membershipProductBlockSlug[Product.MembershipApplicationType.AICPA])
      );

      const cmsProductResponse = cmsResponse.block?.[0]?.cmsProducts;

      if (!cmsProductResponse) {
        throw new Error('Membership Types not found.');
      }
      // set the latest active membership tier/sku in PLD instead of old one (last record)
      userMemberships.reverse();

      if (!!userMemberships?.length && specificProduct?.includedInBenefit) {
        userMemberships.forEach(({ tier, productSlug: prodSlug }) => {
          if (specificProduct?.includedInBenefit) {
            const membershipTypeSlug = cmsProductResponse.find((cms: any) => cms.slug === prodSlug)?.slug;

            specificProduct.includedInBenefit.value.forEach((benefit: any) => {
              if (!benefit?.obj) {
                return;
              }

              const attributeData = benefit.obj.masterData.current.masterVariant.attributes.find(
                ({ name }: any) => name === 'benefitListPartOf'
              );

              if (attributeData?.value) {
                const isEligibleTier = attributeData.value.some(
                  (availableTier: Record<string, string>) => availableTier['en-US'] === tier
                );

                payload = {
                  ...payload,
                  tierName: tier,
                  eligible: isEligibleTier,
                  ...(!isEligibleTier ? { membershipTypeSlug } : {}),
                };

                dispatch(setMembershipPackageTier(tier));
              }
            });
          }
        });
      }

      if (payload) {
        if ((payload as any)?.eligible && specificProduct?.variants?.length && specificProduct.variants[0]?.sku) {
          // @ts-ignore
          dispatch(
            addMembershipPackageSectionFreeProduct({
              productId: specificProduct.productId,
              sku: specificProduct.variants[0].sku,
            })
          );
        }
        return payload;
      }
    }
);

export const searchOrganizationsByWildcardLoading: any = createAction(
  membershipActionNames.QUERY_ORGANIZATIONS_BY_WILDCARD_LOADING
);
export const searchOrganizationsByWildcard: any = createAction(
  membershipActionNames.QUERY_ORGANIZATIONS_BY_WILDCARD,
  (accountName: string, zoomInfoSearch?: boolean, tuitionProviderType?: string) => async (dispatch: Dispatch) => {
    return request(QUERY_ORGANIZATIONS_BY_NAME, {
      accountName,
      isWildcard: true,
      returnAccountNameOnly: true,
      zoomInfoSearch,
      tuitionProviderType,
    })
      .then((response: any) => {
        return { ...response, success: true };
      })
      .catch(() => {
        return { success: false };
      });
  }
);

export const searchOrganizationsCitiesLoading: any = createAction(
  membershipActionNames.QUERY_ORGANIZATIONS_CITIES_LOADING
);
export const searchOrganizationsCities: any = createAction(
  membershipActionNames.QUERY_ORGANIZATIONS_CITIES,
  (accountName: string, cityName: string, zoomInfoSearch?: boolean, tuitionProviderType?: string) =>
    async (dispatch: Dispatch) => {
      return request(QUERY_ORGANIZATIONS_BY_NAME, {
        accountName,
        cityName,
        isCityNameWildcard: true,
        returnCityOnly: true,
        zoomInfoSearch,
        tuitionProviderType,
      })
        .then((response: any) => {
          return { ...response, success: true };
        })
        .catch(() => {
          return { success: false };
        });
    }
);

export const checkIfOrganizationExists: any = createAction(
  membershipActionNames.QUERY_ORGANIZATION_IF_EXISTS,
  (accountName: string) => async (dispatch: Dispatch) => {
    if (!accountName) return false;
    const res = await request(QUERY_ORGANIZATIONS_BY_NAME, {
      accountName,
      returnAccountNameOnly: true,
    });
    return Boolean(res.searchOrganizationsByName?.length);
  }
);

export const clearNewEmployerData: any = createAction(membershipActionNames.CLEAR_NEW_EMPLOYER_DATA);

export const resetEmploymentDataEffect: any = createAction(membershipActionNames.RESET_EMPLOYMENT_EFFECT);

export const getOrganizationByNameAndCity: any = createAction(
  membershipActionNames.QUERY_ORGANIZATION_BY_NAME_AND_CITY,
  (accountName: string, cityName: string) => async (dispatch: Dispatch) => {
    return request(QUERY_ORGANIZATIONS_BY_NAME, {
      accountName,
      cityName,
    })
      .then((response: any) => {
        return response.searchOrganizationsByName[0];
      })
      .catch(() => {
        return { success: false };
      });
  }
);

export const getPracticalExperienceRequirement: any = createAction(
  membershipActionNames.GET_PRACTICAL_EXPERIENCE_REQUIREMENT,
  (endLoading?: boolean) => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(isLoading());

    return request(QUERY_PER_RECORD)
      .then((response: any) => {
        if (response?.getPERRecords) {
          return { ...response.getPERRecords, success: true, endLoading: Boolean(endLoading) };
        }
        return { success: false, endLoading: Boolean(endLoading) };
      })
      .catch(err => {
        return { success: false, endLoading: Boolean(endLoading) };
      });
  }
);

export const updatePracticalExperienceRequirement: any = createAction(
  membershipActionNames.UPDATE_PRACTICAL_EXPERIENCE_REQUIREMENT_RECORD,
  (record: any, portfolio: any, recordType: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(isLoading());
    const state = getState();

    const { location } = state.router;
    const moduleName = getModuleNameFromPath(location.pathname);

    const module = {
      ...record,
      recordType: moduleName || record.recordType || recordType,
      employerAccountId: record?.employer?.id,
      employer: record?.employer,
      supervisor: {
        ...record.supervisor,
        employerAccountId: record.supervisor.employer.id,
      },
      attestation: {
        ...record.attestation,
        createdBy: '',
      },
      operationType: record.operationType,
    };

    if (module.status === MembershipTypes.PracticalExperienceRequirementStatus.DRAFTED) delete module.submissionDate;
    if (module.employmentType === MembershipTypes.PracticalExperienceEmploymentType.FULL_TIME) {
      delete module.numberOfDaysPerWeek;
    }

    if (portfolio) {
      delete portfolio.name;
      delete portfolio.auditRecord;
    }
    return request(MUTATE_SAVE_PER_RECORDS, {
      perRecord: {
        portfolio: portfolio || ({} as MembershipTypes.PERPortfolio),
        module,
      } as MembershipTypes.PERRecord,
    })
      .then((response: any) => {
        if (response?.savePERRecords.success) {
          return { module };
        }
        return { success: false };
      })
      .catch(err => {
        return { success: false };
      });
  }
);

export const removePracticalExperienceRequirement: any = createAction(
  membershipActionNames.REMOVE_PRACTICAL_EXPERIENCE_REQUIREMENT_MODULE_RECORD,
  (perModule: any) => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(isLoading());
    if (!perModule?.id && !perModule?.recordType) return { success: false };
    return request(REMOVE_PER_MODULE, { id: perModule.id, recordType: perModule.recordType })
      .then((response: any) => {
        return response.removePERModule;
      })
      .catch(err => {
        return { success: false };
      });
  }
);

export const updatePracticalExperienceRequirementEmployment: any = createAction(
  membershipActionNames.UPDATE_PRACTICAL_EXPERIENCE_REQUIREMENT_EMPLOYMENT,
  (employment: MembershipTypes.PERRecordPayload) => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(isLoading());

    return request(MUTATE_SAVE_PER_RECORDS, { employment })
      .then((response: any) => {
        if (response?.getPERRecords) {
          return { ...response.getPERRecords.module, success: true };
        }
        return { success: false };
      })
      .catch(err => {
        return { success: false };
      });
  }
);

export const updatePracticalExperienceRequirementActivities: any = createAction(
  membershipActionNames.UPDATE_PRACTICAL_EXPERIENCE_REQUIREMENT_ACTIVITIES,
  (activity: MembershipTypes.PERRecordPayload) => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(isLoading());

    return request(MUTATE_SAVE_PER_RECORDS, { activity })
      .then((response: any) => {
        if (response?.getPERRecords) {
          return { ...response.getPERRecords.module, success: true };
        }
        return { success: false };
      })
      .catch(err => {
        return { success: false };
      });
  }
);

export const updatePracticalExperienceRequirementSkills: any = createAction(
  membershipActionNames.UPDATE_PRACTICAL_EXPERIENCE_REQUIREMENT_SKILLS,
  (skill: any) => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(isLoading());

    return request(MUTATE_SAVE_PER_RECORDS, { skill })
      .then((response: any) => {
        if (response?.getPERRecords) {
          return { ...response.getPERRecords.module, success: true };
        }
        return { success: false };
      })
      .catch(err => {
        return { success: false };
      });
  }
);

export const fetchExemptionEngine: any = createAction(
  membershipActionNames.FETCH_EXEMPTION_ENGINE,
  (id: string, learningPathway: string, isAicpaRegular: boolean, flpSwitchUpgrade: boolean, entrypointLevel?: string) =>
    async () => {
      return request(QUERY_EXEMPTION_ENGINE, {
        id,
        learningPathway,
        isAicpaRegular,
        flpSwitchUpgrade,
        entrypointLevel,
      });
    }
);

export const fetchExemptionLevel: any = createAction(
  membershipActionNames.FETCH_EXEMPTION_LEVEL,
  (id: string, learningPathway: string, entrypointLevel: string) => async () => {
    return request(QUERY_EXEMPTION_LEVEL_REFERENCE_BY_ID, { id, learningPathway, entrypointLevel });
  }
);

export const createPersonMasterExam: any = createAction(
  membershipActionNames.MUTATE_PERSON_EXAM_EXEMPTION,
  (
      createPersonExamExemption: Array<{
        personAccountId: string;
        reason: string;
        subjectId: string;
        isPromoCode?: boolean;
      }>,
      learningPathway: string,
      nonMember?: boolean
    ) =>
    async () => {
      const response = await request(MUTATE_PERSON_EXAM_EXEMPTION, {
        createPersonExamExemption,
        learningPathway,
        nonMember,
      });
      return response;
    }
);

export const updatePersonExamStatus: any = createAction(
  membershipActionNames.MUTATE_PERSON_EXAM_STATUS,
  (personExamStatus: { id: string; status: string }) => async () => {
    const response = await request(MUTATE_PERSON_EXAM_STATUS, { personExamStatus });

    return response;
  }
);

export const getProductVariantsBySku: any = createAction(
  membershipActionNames.GET_SELECTED_CREDENTIALS,
  (SKUs: string[]) =>
    async (dispatch: Dispatch): Promise<any> => {
      const res = Promise.all(
        SKUs.map((sku: string) => {
          return request(GET_PRODUCT_ITEM, { slug: null, sku }).then((response: any) => {
            if (response.getProductBySlugOrSku) {
              const { eligibleExams, num_of_exams, pathway_name, alternativeExams } =
                response.getProductBySlugOrSku?.variants?.find((variant: any) => variant.sku === sku);

              return {
                sku,
                pathway_name,
                eligibleExams,
                num_of_exams,
                alternativeExams,
              };
            }
            return { success: false };
          });
        })
      );
      return res;
    }
);

export const setIsComingFromPropPage: any = createAction(
  membershipActionNames.SET_COMING_FROM_PROP_PAGE,
  (ifComingFromPropPage: boolean) => () => ifComingFromPropPage
);

export const cancelMembership: any = createAction('membership/UPDATE_MEMBERSHIP', (membershipId: string) => () => {
  return request(UPDATE_MEMBERSHIP, { membershipId, status: Salesforce.MembershipStatus.TERMINATED })
    .then(() => {
      return {
        success: true,
        error: '',
        data: null,
      };
    })
    .catch((err: any) => {
      return {
        success: false,
        error: err.message,
        data: null,
      };
    });
});

export const updateCredentialOrSection: any = createAction(
  'membership/UPDATE_CREDENTIAL_SECTION',
  (productType: string, productSku: string) => () => {
    return request(UPDATE_CREDENTIAL_SECTION, {
      productType,
      productSku,
      status: Salesforce.MembershipStatus.TERMINATED,
    })
      .then(() => {
        return {
          success: true,
          error: '',
          data: null,
        };
      })
      .catch((err: any) => {
        return {
          success: false,
          error: err.message,
          data: null,
        };
      });
  }
);

export const setAddCartLoading: any = createAction(
  membershipActionNames.ADD_CART_LOADING,
  (loading: boolean) => () => loading
);

export const setSkuSelected: any = createAction(
  membershipActionNames.SET_SELECTED_SKU_BENEFITS,
  (sku: string[]) => () => sku
);

export const setMembershipSelected: any = createAction(
  membershipActionNames.SET_SELECTED_MEMBERSHIP_BENEFITS,
  (membership: string) => () => membership
);

export const setSectionsCredentialsRenewal: any = createAction(
  membershipActionNames.SET_SECTIONS_CREDENTIALS_RENEWAL,
  (details: { isTriggered: boolean; productType: Product.ProductType | '' }) => () => details
);

export const setInviteDataStatus: any = createAction(
  membershipActionNames.SET_INVITE_DATA_STATUS,
  (status: string) => () => status
);

export const setCredentialProductId: any = createAction(
  membershipActionNames.ADD_CREDENTIAL_PRODUCT_ID_IN_USERCHOICE,
  (credentialProductId: string) => () => credentialProductId
);

export const setFlpDiscountedPRice: any = createAction(
  membershipActionNames.SET_FLP_DISCOUNTED_PRICE,
  (externalPrice: string) => () => externalPrice
);

export const setIsCredentialsJourney: any = createAction(
  membershipActionNames.SET_IS_CREDENTIALS_JOURNEY,
  (isCredentialsJourney: boolean) => () => isCredentialsJourney
);

export const setIsRenewalsJourney: any = createAction(
  membershipActionNames.SET_IS_RENEWALS_JOURNEY,
  (isRenewalsJourney: boolean) => () => isRenewalsJourney
);

export const setIsFLPUpgrade: any = createAction(
  membershipActionNames.SET_IS_FLP_UPGRADE,
  (isFLPUpgrade: boolean) => () => isFLPUpgrade
);
export const setIsFLPSwitch: any = createAction(
  membershipActionNames.SET_IS_FLP_SWITCH,
  (isFLPSwitch: boolean) => () => isFLPSwitch
);

export const setSectionProductId: any = createAction(
  membershipActionNames.ADD_SECTION_PRODUCT_ID_IN_USERCHOICE,
  (sectionProductId: string) => () => sectionProductId
);

export const setFLPVariant: any = createAction(
  membershipActionNames.ADD_FLP_VARIANT_IN_USERCHOICE,
  (flpVariant: string) => () => flpVariant
);

export const setFlpPersonExam: any = createAction(
  membershipActionNames.SET_FLP_PERSON_EXAM,
  (flpPersonExamData: any[]) => () => flpPersonExamData
);

export const setCredentialsItemRenewals: any = createAction(
  membershipActionNames.SET_CREDENTIALS_ITEM_RENEWAL,
  (productId: string, sku: string) => () => {
    return { productId, sku };
  }
);

export const setMembershipEvent: any = createAction(
  membershipActionNames.SET_MEMBERSHIP_EVENT,
  (event: string, value: boolean) => () => {
    return { event, value };
  }
);

export const setPropPageURL: any = createAction(
  membershipActionNames.SET_PROP_PAGE_URL,
  (key: string, value: string) => () => {
    return { key, value };
  }
);

export const removePropPageURL: any = createAction(membershipActionNames.SET_PROP_PAGE_URL, () => () => {
  return true;
});

export const fetchCimaMembershipTypes: any = createAction(
  membershipActionNames.GET_CIMA_MEMBERSHIP_TYPES,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    try {
      const state = getState();
      const cimaPqTypeBlock = 'cima-choose-your-membership-type';
      const personalDetail = personAccountDataSelector(state);
      const isUserMember = isUserMemberSelector(state);
      const isUserMemberSuspended = isUserMemberSuspendedSelector(state);
      const isUserMemberLapsed = isUserMemberLapsedSelector(state);
      const learningPathway = learningPathwaySelector(state) as string;
      const membershipType = cimaMembershipsTermTypeSelector(state);
      const isForUpgrade = clickedMembershipUpgradeSelector(state);
      const isCimaPqCandidateRenewal = isCimaPqCandidateRenewalSelector(state);
      const isEPA1Completed = isEPA1CompletedSelector(state);
      const isEPA2Completed = isEPA2CompletedSelector(state);
      const perStatus = practicalExperienceRequirementStatusSelector(state);
      const isSubscribedToFlp = AdminUtils.isFlpPathway(learningPathway as string);
      const cimaMemBody = cimaMembershipSelector(state)?.membershipBody as string;
      const isAuth = isAuthSelector(state);
      const isAdmin = isAdminPortalSelector(state);
      const isCimaPQCandidateLapsed = isCimaPQCandidateLapsedSelector(state);
      const isImpersonated = Boolean(isAuth) && isAdmin;

      const isSubscribedToCimaRegular = AdminUtils.isCimaRegularType(cimaMemBody, membershipType);
      const isSubscribedToCimaAffiliate = AdminUtils.isCimaAffiliateType(cimaMemBody, membershipType);
      const isSubscribedToCimaRetired = AdminUtils.isCimaRetiredType(cimaMemBody, membershipType);
      const isSubscribedToCimaCgmaAffiliate = AdminUtils.isCimaCgmaAffiliateType(cimaMemBody, membershipType);
      const isCimaCgmaAffilateLapsed = isCimaCgmaAffiliateLapsedSelector(state);
      const isSubscribedToCimaCandidate = isCimaCandidateTypeSelector(state);
      const isUpgradeToRegular = areAllTruthy(
        learningPathway === MembershipTypes.Pathway.APPRENTICE_L7,
        isEPA1Completed,
        isEPA2Completed
      );
      const isPERStatusCompleted = perStatus === MembershipTypes.PERPortfolioStatus.COMPLETED;
      const isUpgradeFlpToRegular = areAllTruthy(isSubscribedToFlp, isPERStatusCompleted);

      const numberOfDowngrades = numberOfDowngradesSelector(state);
      const isCimaRegularLapsed = isCimaRegularLapsedSelector(state);
      const yearDiff = moment().diff(personalDetail.birthDate, 'years');
      const isRetired = areAllTruthy(moment(personalDetail.birthDate).isValid(), yearDiff > 65);

      const membershipQuery = QUERY_MEMBERSHIP_PRODUCT_BLOCK(cimaPqTypeBlock);

      const resMembershipQuery = await request(membershipQuery);

      const cmsProductResponse = resMembershipQuery.block?.[0]?.cmsProducts;

      if (!cmsProductResponse) {
        throw new Error('Membership Types not found.');
      }

      const response = {
        filteredCmsProducts: cmsProductResponse,
        allCmsProducts: cmsProductResponse,
      };

      if (isUserMember) {
        if (!isSubscribedToCimaCandidate) {
          response.filteredCmsProducts = cmsProductResponse.filter(
            (type: any) => type.membershipKey !== MembershipTypes.MembershipKeys.CANDIDATE
          );
        }

        if (
          areAllTruthy(
            !isSubscribedToCimaCgmaAffiliate,
            !areAllTruthy(isCimaPqCandidateRenewal, isSubscribedToCimaCandidate)
          )
        ) {
          response.filteredCmsProducts = cmsProductResponse.filter(
            (type: any) => type.membershipKey !== MembershipTypes.MembershipKeys.CGMA_AFFILIATE
          );
        }
      }

      if (areAllTruthy(!isUserMember, !isUserMemberSuspended, !isUserMemberLapsed, !isCimaPQCandidateLapsed)) {
        return response;
      }

      // TODO: Change filtering to keys instead of slugs
      if (
        hasTruthyValue(areAllTruthy(isCimaPqCandidateRenewal, isSubscribedToCimaCandidate), isCimaPQCandidateLapsed)
      ) {
        const allowedNumberofDowngrades =
          cmsProductResponse?.find((type: any) => type.membershipKey === MembershipTypes.MembershipKeys.CGMA_AFFILIATE)
            ?.allowedDowngrades ?? 1;

        if (hasTruthyValue(isImpersonated, allowedNumberofDowngrades > numberOfDowngrades)) {
          response.filteredCmsProducts = cmsProductResponse.filter((type: any) =>
            hasTruthyValue(
              type.membershipKey === MembershipTypes.MembershipKeys.CANDIDATE,
              type.membershipKey === MembershipTypes.MembershipKeys.CGMA_AFFILIATE
            )
          );
          return response;
        }
        response.filteredCmsProducts = cmsProductResponse.filter(
          (type: any) => type.membershipKey === MembershipTypes.MembershipKeys.CANDIDATE
        );
        return response;
      }

      if (isForUpgrade) {
        if (isSubscribedToCimaCgmaAffiliate) {
          response.filteredCmsProducts = cmsProductResponse.filter(
            (type: any) => type.membershipKey === MembershipTypes.MembershipKeys.CANDIDATE
          );
          return response;
        }

        if (hasTruthyValue(isUpgradeToRegular, isUpgradeFlpToRegular, isSubscribedToCimaAffiliate)) {
          if (isRetired) {
            response.filteredCmsProducts = cmsProductResponse.filter((type: any) =>
              hasTruthyValue(
                type.membershipKey === MembershipTypes.MembershipKeys.RETIRED,
                type.membershipKey === MembershipTypes.MembershipKeys.REGULAR
              )
            );
            return response;
          }
          response.filteredCmsProducts = cmsProductResponse.filter(
            (type: any) => type.membershipKey === MembershipTypes.MembershipKeys.REGULAR
          );
          return response;
        }
      } else {
        if (hasTruthyValue(isSubscribedToCimaCgmaAffiliate, isCimaCgmaAffilateLapsed)) {
          response.filteredCmsProducts = cmsProductResponse.filter((type: any) =>
            hasTruthyValue(
              type.membershipKey === MembershipTypes.MembershipKeys.CANDIDATE,
              type.membershipKey === MembershipTypes.MembershipKeys.CGMA_AFFILIATE
            )
          );
          return response;
        }

        if (isSubscribedToCimaAffiliate) {
          if (isRetired) return response;

          response.filteredCmsProducts = cmsProductResponse.filter(
            (type: any) => type.membershipKey !== MembershipTypes.MembershipKeys.RETIRED
          );
          return response;
        }
        if (isSubscribedToCimaRegular) {
          if (isRetired) {
            response.filteredCmsProducts = cmsProductResponse.filter((type: any) =>
              hasTruthyValue(
                type.membershipKey === MembershipTypes.MembershipKeys.RETIRED,
                type.membershipKey === MembershipTypes.MembershipKeys.REGULAR
              )
            );
            return response;
          }

          response.filteredCmsProducts = cmsProductResponse.filter(
            (type: any) => type.membershipKey === MembershipTypes.MembershipKeys.REGULAR
          );
          return response;
        }
        if (isSubscribedToCimaRetired) {
          response.filteredCmsProducts = cmsProductResponse.filter(
            (type: any) => type.membershipKey === MembershipTypes.MembershipKeys.RETIRED
          );
          return response;
        }
        if (isCimaRegularLapsed) {
          if (isRetired) {
            response.filteredCmsProducts = cmsProductResponse.filter((type: any) =>
              hasTruthyValue(
                type.membershipKey === MembershipTypes.MembershipKeys.RETIRED,
                type.membershipKey === MembershipTypes.MembershipKeys.REGULAR
              )
            );
            return response;
          }
          response.filteredCmsProducts = cmsProductResponse.filter(
            (type: any) => type.membershipKey === MembershipTypes.MembershipKeys.REGULAR
          );
          return response;
        }
      }
      return response;
    } catch (error) {
      return Promise.reject((error as any)?.response?.message);
    }
  }
);

export const setUserchoiceType: any = createAction(
  membershipActionNames.SET_USERCHOICE_TYPE,
  (upgradeMembershipKey?: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    // TODO: This is a temporary action to add Cima Regular, Affiliate and Retired type to cart
    try {
      const state = getState();
      const userCimaTypes = filteredCIMAMembershipTypesSelector(state);
      const cimaMembershipType = getCimaMembershipTypeSelector(state);

      const membershipType = User.CimaMembershipTypes[cimaMembershipType];

      if (!membershipType && !upgradeMembershipKey) return;

      const membershipKey = upgradeMembershipKey ? upgradeMembershipKey : membershipType?.membershipKey;
      const cimaMembership = await userCimaTypes?.find(type => type.membershipKey === membershipKey);

      await dispatch(setMembershipPackageType(cimaMembership?.id, cimaMembership?.slug));
      await dispatch(fetchMembershipTiers(false, '', false, false, membershipType?.tierCode));
    } catch (error) {
      return Promise.reject((error as any)?.response?.message);
    }
  }
);

export const setRoleFormDataEmployment: any = createAction(
  membershipActionNames.SET_ROLE_FORM_DATA,
  (formData: Partial<any>) => async (dispatch: Dispatch, getState: () => State.Root) => {
    return formData;
  }
);

export const setEmployerFormDataEmployment: any = createAction(
  membershipActionNames.SET_EMPLOYER_FORM_DATA,
  (formData: Partial<any>) => async (dispatch: Dispatch, getState: () => State.Root) => {
    return formData;
  }
);

export const setNewEmployment: any = createAction(
  membershipActionNames.SET_EMPLOYER_DATA,
  (employerData: Partial<any>) => async (dispatch: Dispatch, getState: () => State.Root) => {
    return employerData;
  }
);

export const setNewRoleData: any = createAction(
  membershipActionNames.SET_EMPLOYMENT_ROLE_DATA,
  (roleData: Partial<any>) => async (dispatch: Dispatch, getState: () => State.Root) => {
    return roleData;
  }
);

export const setSupervisorData: any = createAction(
  membershipActionNames.SET_EMPLOYMENT_SUPERVISOR_DATA,
  (supervisorData: Partial<any>) => async (dispatch: Dispatch, getState: () => State.Root) => {
    return supervisorData;
  }
);

export const setSupervisorEmployerData: any = createAction(
  membershipActionNames.SET_EMPLOYMENT_SUPERVISOR_EMPLOYER_DATA,
  (supervisorEmployerData: Partial<any>) => async (dispatch: Dispatch, getState: () => State.Root) => {
    return supervisorEmployerData;
  }
);

export const setFormDataEmployment: any = createAction(
  membershipActionNames.SET_FORM_DATA_EMPLOYMENT,
  (formData: Partial<any>) => async (dispatch: Dispatch, getState: () => State.Root) => {
    return formData;
  }
);

export const setSupervisorFormData: any = createAction(
  membershipActionNames.SET_EMPLOYMENT_SUPERVISOR_FORM_DATA,
  (supervisorFormData: Partial<any>) => async (dispatch: Dispatch, getState: () => State.Root) => {
    return supervisorFormData;
  }
);
export const setSupervisorEmployerFormData: any = createAction(
  membershipActionNames.SET_EMPLOYMENT_SUPERVISOR_EMPLOYER_FORM_DATA,
  (supervisorEmployerFormData: Partial<any>) => async (dispatch: Dispatch, getState: () => State.Root) => {
    return supervisorEmployerFormData;
  }
);
export const clearSupervisorEmploymentFormData: any = createAction(
  membershipActionNames.CLEAR_SUPERVISOR_EMPLOYMENT_FORM_DATA
);
export const clearSupervisorEmployerAddressFormData: any = createAction(
  membershipActionNames.CLEAR_SUPERVISOR_EMPLOYER_ADDRESS_FORM_DATA
);
export const clearSupervisorFormData: any = createAction(membershipActionNames.CLEAR_SUPERVISOR_FORM_DATA);

export const clearEmploymentData: any = createAction(membershipActionNames.CLEAR_EMPLOYMENT_DATA);
export const clearEmploymentAddressData: any = createAction(membershipActionNames.CLEAR_EMPLOYMENT_ADDRESS_DATA);
export const clearExistingEmploymentData: any = createAction(membershipActionNames.CLEAR_EXISTING_EMPLOYMENT_DATA);
export const clearExistingEmploymentAddressData: any = createAction(
  membershipActionNames.CLEAR_EXISTING_EMPLOYMENT_ADDRESS_DATA
);
export const clearSupervisorEmploymentData: any = createAction(membershipActionNames.CLEAR_SUPERVISOR_EMPLOYMENT_DATA);
export const clearSupervisorData: any = createAction(membershipActionNames.CLEAR_SUPERVISOR_DATA);
export const clearNewEmploymentData: any = createAction(membershipActionNames.CLEAR_NEW_EMPLOYMENT_DATA);
export const clearSupervisorEmployerAddress: any = createAction(
  membershipActionNames.CLEAR_SUPERVISOR_EMPLOYER_ADDRESS
);

export const getAllPathwayProducts: any = createAction(
  membershipActionNames.GET_ALL_PRODUCTS_PATHWAY,
  (isChinesePathway?: boolean) => async (dispatch: Dispatch, getState: () => State.Root) => {
    try {
      const response = await request(GET_ALL_PRODUCTS_PATHWAY, { isChinesePathway });
      const pathwaysProduct: State.PathwayProduct[] = response.getAllPathwayProducts;

      const list = pathwaysProduct.map((data: State.PathwayProduct) => ({
        ...data,
      }));

      const setOfCategories = pathwaysProduct.reduce((agg, data: State.PathwayProduct) => {
        if (data.categories) {
          data.categories.forEach(category => {
            agg.add(category.name);
          });
        }
        return agg;
      }, new Set());

      const categories = Array.from(setOfCategories)
        .map(categoryName => {
          return {
            name: categoryName,
            selected: false,
            page: 1,
          };
        })
        .concat({ name: 'All', selected: true, page: 1 })
        .reverse();

      return {
        list: !!list.length ? list : [],
        categories: !!categories.length ? categories : [],
      };
    } catch (error) {
      return Promise.reject((error as any)?.response?.message || error);
    }
  }
);

export const addMembershipPackagePathway: any = createAction(
  membershipActionNames.ADD_MEMBERSHIP_PACKAGE_RELATED_PATHWAY,
  (productId: string, sku: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const { list } = membershipRelatedPathwaysSelector(state);

    const bundlePathway = list?.find(bundle => bundle.productId === productId);

    const productsToInclude =
      bundlePathway?.bundleProducts?.map(bundleProduct => ({
        productId: bundleProduct?.productId,
        sku: bundleProduct?.variants?.[0]?.sku,
        productType: bundleProduct?.productType,
        slug: bundleProduct?.slug,
      })) || [];

    const membershipProduct = productsToInclude?.find(
      product => product?.productType === Product.ProductType.MEMBERSHIP
    );
    // Set Type
    dispatch(setMembershipPackageType(membershipProduct?.productId, membershipProduct?.slug, true, false, true));
    // Set Tier
    dispatch(setMembershipPackageTier(membershipProduct?.sku));
    // Set Membership Product Value
    dispatch(
      setMembershipProduct(
        bundlePathway?.bundleProducts?.find(
          bundleProduct => bundleProduct?.productType === Product.ProductType.MEMBERSHIP
        )
      )
    );

    dispatch(setSelectedPathwayBundleId(bundlePathway?.productId || ''));

    return productsToInclude?.reduce((acc: any, item) => [...acc, { productId: item.productId, sku: item.sku }], []);
  }
);

export const removeMembershipPackageRelatedPathway: any = createAction(
  membershipActionNames.REMOVE_MEMBERSHIP_PACKAGE_RELATED_PATHWAY
);

export const setIsAllowToAddFcmaInCart: any = createAction(membershipActionNames.SET_IS_ALLOW_TO_ADD_FCMA_IN_CART);

export const setHasSelectedFcmaDesignation: any = createAction(membershipActionNames.SET_HAS_SELECTED_FCMA_DESIGNATION);

export const setSelectedPathwayBundleId: any = createAction(
  membershipActionNames.SET_SELECTED_PATHWAY_BUNDLE_ID,
  (id: string) => () => id
);

export const setMembershipProduct: any = createAction(
  membershipActionNames.SET_MEMBERSHIP_PRODUCT,
  (product: any) => () => product
);

export const setIsOpenEmploymentModalFromInvite: any = createAction(
  membershipActionNames.SET_IS_OPEN_EMPLOYMENT_MODAL_FROM_INVITE,
  (isOpen: boolean) => () => isOpen
);

export const setChangeMyLearningPath: any = createAction(membershipActionNames.SET_CHANGE_MY_LEARNING_PATH);

export const setIsLearningPathToChangeMatchedPreviouslySelectedPath: any = createAction(
  membershipActionNames.SET_IS_LEARNING_PATH_TO_CHANGE_MATCHED_PREVIOUSLY_SELECTED_PATH
);
export const getEntryLevels: any = createAction(membershipActionNames.SET_ENTRY_LEVELS, (slug: string) => () => {
  return request(QUERY_ENTRY_LEVEL, { slug });
});

export const setOfflineExemptionsEntryLevels: any = createAction(
  membershipActionNames.SET_OFFLINE_EXEMPTIONS_ENTRY_LEVELS,
  (slugs: string[]) => async () => {
    // Call graphql query for all entryLevels that will be used for offline exemption calculator summary
    const promises = slugs.map(slug => request(QUERY_ENTRY_LEVEL, { slug }));
    const results = await Promise.all(promises);

    return results.map((item: any) => {
      return { ...item.getEntryLevels };
    }, {});
  }
);

export const getAttestations: any = createAction(
  membershipActionNames.GET_ATTESTATIONS,
  async () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    dispatch(setAttestationsIsFetched(false));
    const attestationsToQuery = selectedAttestationsSelector(state);
    const attestations = await request(QUERY_ATTESTATIONS, { attestationsToQuery });
    dispatch(setAttestationsIsFetched(true));
    return attestations !== null ? attestations : initialState.attestations;
  }
);

export const setAttestationsIsFetched: any = createAction(
  membershipActionNames.SET_ATTESTATION_IS_FETCHING,
  (state: boolean) => (dispatch: Dispatch, getState: () => State.Root) => state
);

export const appendAttestationToQuery: any = createAction(
  membershipActionNames.APPEND_ATTESTATION_QUERY,
  (category: string, value: string) => () => {
    return {
      category,
      value,
    };
  }
);

export const addSelectedMembershipBenefit: any = createAction(
  membershipActionNames.ADD_MEMBERSHIP_SELECTED_BENEFIT,
  (selectedBenefit: any) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const selectedBenefits = selectedBenefitsSelector(state);

    return [...selectedBenefits, selectedBenefit];
  }
);

export const getPERFinancialBodies: any = createAction(
  membershipActionNames.GET_PER_FINANCIAL_BODIES,
  () => async () => {
    const { getPERFinancialBodies: PERFinancialBodies } = await request(GET_PER_FINANCIAL_BODIES);

    return PERFinancialBodies;
  }
);

export const getMembershipInviteData = createAction(
  membershipActionNames.GET_MEMBERSHIP_INVITE_DATA,
  (inviteId: string) => async () => {
    const apiResponse = await request(GET_MEMBERSHIP_INVITE_DATA, { inviteId });

    const response: ExtendedInvite = apiResponse.getMembershipInviteData;

    return response;
  }
);

export const setHasNotMetMinimumRequirements: any = createAction(
  membershipActionNames.SET_HAS_NOT_MEET_MINIMUM_REQUIREMENTS,
  (activeLevel: any) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const inviteData = membershipInviteDataSelector(state);
    const defaultExemption = exemptionLevelEntrySelector(state);

    return inviteData?.inviteId && activeLevel?.levelName && defaultExemption?.name !== activeLevel?.levelName;
  }
);
export const setIsFlpModalOpen = createAction(
  membershipActionNames.SET_IS_FLP_MODAL_OPEN,
  (isOpen: boolean, modalVariant: any) => {
    return { isOpen, modalVariant };
  }
);
export const setIsCimaMembershipPageJourney: any = createAction(
  membershipActionNames.SET_IS_CIMA_MEMBERSHIP_PAGE_JOURNEY,
  (isComingFromCimaMembershipPage: boolean, previousPath: string) => {
    return { isComingFromCimaMembershipPage, previousPath };
  }
);

export const setFcmaInUserChoice: any = createAction(
  membershipActionNames.SET_FCMA_IN_USER_CHOICE,
  (productId: string, sku: string) => {
    return { productId, sku };
  }
);

export const setAttestation: any = createAction(
  membershipActionNames.SET_ATTESTATION,
  (categoryName: Contentful.Attestations.CategoryType, categoryValue: Contentful.Attestations.CategoryValueType) => {
    return {
      categoryName,
      categoryValue,
    };
  }
);

// TODO: Move to server - getCustomerProfile.
// This is only used for lapsed users that have no data in productListData (CT)
export const getInactiveMembershipSlug: any = createAction(
  membershipActionNames.GET_INACTIVE_MEMBERSHIP_PRODUCT_NAME,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const { membership } = inactiveMembershipProduct(state);
    const inactiveTermSku = membership?.inactiveTerms?.[0]?.productId;
    const { payload } = await dispatch(getESProductBySku(inactiveTermSku));
    return payload;
  }
);

export const setRenewalProducts: any = createAction(
  membershipActionNames.SET_RENEWAL_PRODUCTS,
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const memberships = currentMembershipProduct(state);
    const credentials = currentCredentialProducts(state);
    const sections = currentSectionProducts(state);

    return {
      memberships,
      credentials,
      sections,
    };
  }
);

export const modifyMembershipAccordion = createAction(
  membershipActionNames.MODIFY_MEMBERSHIP_ACCORDION,
  (type: RenewMembershipOptions) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const useNewMembershipAicpa = getFeatureToggleByKeySelector(state, USE_NEW_MEMBERSHIP_AICPA);
    const isMembershipSectionsFetched = isMembershipSectionsFetchedSelector(state);

    if (useNewMembershipAicpa) {
      const { list: benefitsList } = userMembershipBenefitsSelector(state);
      const { tier } = userMembershipPackageSelector(state);

      switch (type) {
        case RenewMembershipOptions.MEMBERSHIP_CREDENTIALS:
          dispatch(fetchMembershipCredentials());
          break;

        case RenewMembershipOptions.ADD_ONS:
          if (tier && !benefitsList?.length) {
            dispatch(fetchMembershipRelatedBenefits(tier));
          }
          dispatch(fetchMembershipRelatedAddons());
          break;

        case RenewMembershipOptions.MEMBERSHIP_SECTIONS:
          if (!isMembershipSectionsFetched) {
            dispatch(fetchMembershipSections());
          }
          break;
      }
    }
  }
);

export const setHasSelectedCredential: any = createAction(
  membershipActionNames.SET_HAS_SELECTED_CREDENTIAL,
  (hasSelectedCredential: boolean) => () => hasSelectedCredential
);

export const setHasSelectedSection: any = createAction(
  membershipActionNames.SET_HAS_SELECTED_SECTION,
  (hasSelectedSection: boolean) => () => hasSelectedSection
);

export const setHasSelectedTier: any = createAction(
  membershipActionNames.SET_HAS_SELECTED_TIER,
  (hasSelectedTier: boolean) => () => hasSelectedTier
);

export const setHasSelectedType: any = createAction(
  membershipActionNames.SET_HAS_SELECTED_TYPE,
  (hasSelectedType: boolean) => () => hasSelectedType
);

export const setHasSelectedAddOns: any = createAction(
  membershipActionNames.SET_HAS_SELECTED_ADDONS,
  (hasSelectedAddOns: boolean) => () => hasSelectedAddOns
);
