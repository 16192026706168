import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { getLocation, getSearch } from 'connected-react-router';
import {
  ADD_BUNDLE_CART_ITEM,
  ADD_CART_ITEM,
  ADD_CART_SHIPPING_ADDRESS,
  ADD_PROMO,
  GET_CART,
  MERGE_CART_ITEMS_TO_BUNDLE,
  MIGRATE_CART,
  REMOVE_CART_ITEMS,
  REMOVE_PROMO,
  SET_SHIPPING_CART,
  UPDATE_CART_ITEM,
  PROCESS_DONATION_CART_ITEMS,
} from 'mxp-graphql-queries';
import { default as request } from 'utils/GraphQLClient';
import {
  appliedPromoCodeIdSelector,
  cartIdSelector,
  cartSelector,
  cartVersionSelector,
  isCartEmptySelector,
  phProductsInArrCheckSelector,
  isExistingBundleCartItemSelector,
  lineItemsSelector,
  bundleComponentProductVariantExistingInCartBundleItemsSelector,
} from './selectors';
import { Cart, Checkout, Product } from 'mxp-schemas';
import {
  centerMembershipPackageOrganizationSelector,
  isAuthSelector,
  userRolesSelector,
  userLocationSelector,
  isUserSuspendedSelector,
  isUserMemberSelector,
} from 'modules/user/selectors';
import {
  filteredProductVariantsSelector,
  thirdPartyLinkSelector,
  isPhysicalProductSelector,
  getProductIncludedMembershipSelector,
  getProductContentRoleIdSelector,
  isProductTypeWithMultipleOptionsSelector,
  bundleProductsProductIdsSelector,
  productPageItemSelector,
  bundleProductsSelector,
  bundleItemsSelectionInfoSelector,
  BundleItemsSelectionInfo,
  donationPriceSelector,
  productCurrencySelector,
  hasExistingZuoraPurchaseSelector,
} from '../products/selectors';
import { getAlreadyHasAccessInfo } from 'components/pages/PageProduct/pageProductHelper';
import { getAnonCartInfo, getMatchingLineItems, removeAnonCartInfo, storeAnonCartInfo } from './helpers';
import { CheckoutCountriesListHash } from 'mxp-utils';
import { Routes, StorageNames } from 'constants/index';
import { getPath } from 'utils';
import {
  setCartBundleMergeBannerMessage,
  setRecentlyAddedBundleDiscountPercentage,
  clearCartSingleTimeBanners,
  setCartMigrationMessages,
} from 'modules/layouts';
import { VariantInfo } from 'mxp-schemas/src/types/carts';
import { getProductItem } from 'modules/products/reducers';
import qs from 'query-string';
import { getFeatureToggleByKeySelector } from 'modules/featureToggle/selectors';
import { USE_PRODUCTS_PRICE_CURRENCY, USE_SUSPENDED_ETHICS_STATUS_RESTRICTION } from 'modules/featureToggle/constants';
import { updatePreferredCurrency } from 'modules/products/actions';
import { removeLocalStorageItem } from 'utils/localStorage';

export interface AnonCartInfo {
  cartId: string;
  cartVersion: number;
}

// ------------------------------------
// Actions
// ------------------------------------
export const loading: any = createAction('cart/LOADING');
export const isLoadingShippingOptions: any = createAction('cart/LOADING_SHIPPING_OPTIONS');

export const getCartOrMigrate: any = createAction(
  'cart/LOGGED_IN_GET_OR_MIGRATE_CART',
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const anonCartInfo = getAnonCartInfo();
    const isAuth = isAuthSelector(state);

    if (isAuth) {
      if (anonCartInfo) {
        return dispatch(migrateUserCart(anonCartInfo));
      }
      return dispatch(getCart());
    }
    // This action is only relevant for registered users, do nothing
    return Promise.resolve();
  }
);

export const updatePONumber: any = createAction('cart/SET_PO_NUMBER');

export const setCart: any = createAction('cart/SET_CART');

export const getCart: any = createAction('cart/GET_CART', () => (dispatch: Dispatch, getState: () => State.Root) => {
  dispatch(loading());
  const state = getState();
  const isAuth = isAuthSelector(state);
  const anonCartInfo = getAnonCartInfo();
  const cartIdForAnonUser = !isAuth ? anonCartInfo?.cartId : null;
  const isUserMember = isUserMemberSelector(state);

  const search = getSearch(state);
  const queryParams = qs.parse(search);
  if (queryParams?.sku) dispatch(getProductItem(null, queryParams?.sku));
  if (!isAuth && !cartIdForAnonUser) {
    // Anon users with no cartId, set the cart to null
    return Promise.resolve(null);
  }
  if (isAuth && anonCartInfo?.cartId) {
    // We should never end up here, remove the cart and continue fetching the latest cart for the registered user
    removeAnonCartInfo();
  }
  // Either logged in user OR anon with a cart id
  const { pathname, state: locationState } = getLocation<any, { prevRoute: string }>(state);
  const isCartPage: boolean = [pathname, locationState?.prevRoute].includes(getPath(Routes.CART_PAGE));
  return request(GET_CART, { recalculate: true, cartId: cartIdForAnonUser, addUpsellInfo: isCartPage }).then(
    //  Success just return the cart even if undefined
    ({ getCart: cart }: { getCart: Cart.Cart }) => {
      if (!cart && !isAuth && cartIdForAnonUser) {
        // this should not happen, clean anon cart info
        removeAnonCartInfo();
      }
      if (cart && isAuth && isUserMember) {
        dispatch(setProductsWithEthics(cart));
      }
      return cart;
    },
    // Failure, gracefully fail on 404s
    error => {
      if (error.response?.errors?.length === 1 && error.response.errors[0].status === 404) {
        // Fail silently, its normal not to have a cart
        if (!isAuth && cartIdForAnonUser) {
          // remove anon cart info
          removeAnonCartInfo();
        }
        return undefined;
      }
      return Promise.reject('Error retrieving the cart');
    }
  );
});

export const migrateUserCart: any = createAction(
  'cart/MIGRATE_USER_CART',
  (anonCartInfo: AnonCartInfo) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();

    dispatch(loading());
    dispatch(resetCartData());

    const currency = productCurrencySelector(state);
    const useProductsPriceCurrency = getFeatureToggleByKeySelector(state, USE_PRODUCTS_PRICE_CURRENCY);
    const userLocation = userLocationSelector(state);

    const cartValue = cartSelector(state);
    let currLabel = Product.ProductCurrencyLabel.USD;

    if (!!cartValue?.lineItems?.length && cartValue?.totalPrice?.currencyCode) {
      currLabel = (cartValue?.totalPrice?.currencyCode as Product.ProductCurrencyLabel) || currency.label;
    }

    return request(MIGRATE_CART, {
      ...anonCartInfo,
      currencyLabel: currLabel,
      userLocation: userLocation?.country || 'US',
      useProductsPriceCurrency,
    })
      .then(({ migrateCart: cart }: { [key: string]: any }) => {
        removeAnonCartInfo();
        dispatch(setCartMigrationMessages(cart.migrationMessages));
        return cart;
      })
      .catch(() => {
        // Fail silently
        removeAnonCartInfo();
      });
  }
);

export const addCartItem: any = createAction(
  'cart/ADD_CART_ITEM',
  (
      productId: string,
      variantSKU: string,
      cartValidation: {
        quantityValue: number | null;
        isChecked: boolean;
      } | null,
      fromQueryParam: boolean = false,
      asPartOfToBundleId?: string,
      freeTrial: boolean = false,
      activeMIPSubscription?: State.LineItemWithAccessDates | undefined
    ) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const state = getState();
      const cart = cartSelector(state);
      const isCartEmpty = isCartEmptySelector(state);
      const currency = productCurrencySelector(state);
      const useProductsPriceCurrency = getFeatureToggleByKeySelector(state, USE_PRODUCTS_PRICE_CURRENCY);
      const useSuspendedRestriction = getFeatureToggleByKeySelector(state, USE_SUSPENDED_ETHICS_STATUS_RESTRICTION);
      const userLocation = userLocationSelector(state);
      const isUserSuspendedByEthics = isUserSuspendedSelector(state);
      dispatch(setRecentlyAddedBundleDiscountPercentage(null));
      const donationPrice = donationPriceSelector(state);
      const userEthicsStatus: boolean = Boolean(useSuspendedRestriction && isUserSuspendedByEthics);
      if (fromQueryParam) {
        const isPhysicalProduct = isPhysicalProductSelector(state);
        const thirdPartyLink = thirdPartyLinkSelector(state);
        const hasThirdPartyLink = Boolean(thirdPartyLink && !isPhysicalProduct);
        const userRoles = userRolesSelector(state);
        const productIncludedMembership = getProductIncludedMembershipSelector(state);
        const productContentRoleId = getProductContentRoleIdSelector(state);
        const alreadyHasAccessInfo = getAlreadyHasAccessInfo(
          userRoles,
          productContentRoleId,
          productIncludedMembership
        );
        const alreadyHasAccess = Boolean(alreadyHasAccessInfo.message);
        const productItem = state.products?.productItem;
        const filteredVariants = filteredProductVariantsSelector(state);
        const isProductTypeWithMultipleOptions = isProductTypeWithMultipleOptionsSelector(state);
        const isWebcast: boolean = productItem?.productType === Product.ProductType.WEBCAST;
        const isWebcastSeries: boolean = isWebcast && productItem?.format?.key === Product.AvailableFormat.SERIES;
        const variant = filteredVariants.find(variants => variants.sku === variantSKU);
        const isContribution: boolean = productItem?.productType === Product.ProductType.CONTRIBUTION;
        let isPhysicalProductOutOfStock = Boolean(
          !isProductTypeWithMultipleOptions && !productItem?.availability && isPhysicalProduct
        );

        if (isProductTypeWithMultipleOptions || isWebcastSeries) {
          isPhysicalProductOutOfStock = Boolean(!variant?.availability?.isOnStock && isPhysicalProduct);
        }

        productId = productItem?.productId; // tslint:disable-line
        cartValidation = { quantityValue: 1, isChecked: false }; // tslint:disable-line

        const isValidSKU = Boolean(
          variant && !hasThirdPartyLink && !alreadyHasAccess && !isPhysicalProductOutOfStock && !isContribution
        );
        if (!isValidSKU) return cart;
      }

      const isInCart =
        !isCartEmpty &&
        Boolean(
          cart.lineItems.filter((item: Cart.LineItem) => {
            const sameProduct = item.productId === productId;
            const sameVariant = item.variant ? item.variant.sku === variantSKU : false;
            return sameProduct && sameVariant;
          }).length
        );

      if (isInCart) return cart;

      const isAuth = isAuthSelector(state);
      dispatch(loading());
      if (productId && variantSKU) {
        const anonCartInfo = getAnonCartInfo();

        const cartId = anonCartInfo?.cartId;
        const shouldPassAnonCartId = !isAuth && cartId;
        const quantity = cartValidation?.quantityValue || 1;

        return request(ADD_CART_ITEM, {
          productId,
          variantSKU,
          quantity,
          standingOrder: Boolean(cartValidation?.isChecked),
          asPartOfToBundleId,
          ...(shouldPassAnonCartId && { cartId }),
          donationPrice,
          freeTrial,
          currencyLabel: currency.label,
          userLocation: userLocation?.country || 'US',
          useProductsPriceCurrency,
          ethicStatus: userEthicsStatus,
          activeMIPSubscription,
        }).then(({ addCartItem: updatedCart }: { [key: string]: any }) => {
          return updateCartDetailsInStorage(state, dispatch, updatedCart);
        });
      }
    }
);

export const addBundleCartItemWithCheckForExistingVariants: any = createAction(
  'cart/CHECK_BUNDLE_BEFORE_ADDING_TO_CART',
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const lineItemsToRemove: string[] = bundleComponentProductVariantExistingInCartBundleItemsSelector(state);
    if (lineItemsToRemove.length) {
      const bundleProduct: Product.ProductItem = productPageItemSelector(state);
      const bundleDiscountPercentage: number | undefined = bundleProduct?.bundleDiscountPercent;
      dispatch(removeCartItems(lineItemsToRemove)).then(() => {
        if (bundleDiscountPercentage) {
          dispatch(setRecentlyAddedBundleDiscountPercentage(bundleDiscountPercentage));
        }
        dispatch(addBundleCartItem());
      });
      return;
    }
    dispatch(setRecentlyAddedBundleDiscountPercentage(null));
    dispatch(addBundleCartItem());
    return;
  }
);

export const addBundleCartItem: any = createAction(
  'cart/ADD_BUNDLE_CART_ITEM',
  () =>
    async (dispatch: Dispatch, getState: () => State.Root): Promise<Cart.Cart> => {
      const state = getState();
      const cart: Cart.Cart = cartSelector(state);

      const isInCart = isExistingBundleCartItemSelector(state);
      if (isInCart) return cart;

      const isAuth = isAuthSelector(state);
      const bundleProductsIds = bundleProductsProductIdsSelector(state);
      const bundleProducts = bundleProductsSelector(state);
      const bundleSelectionInfo = bundleItemsSelectionInfoSelector(state);
      const isUserSuspendedByEthics = isUserSuspendedSelector(state);
      const bundleId = state.products?.productItem?.productId;

      if (!bundleSelectionInfo?.length || bundleProducts?.length !== bundleSelectionInfo.length) return cart;

      dispatch(loading());

      interface BundleAnalysisResult {
        removalPromises: Promise<any>[];
        variantInfos: VariantInfo[];
        addBannerForPhysical: boolean;
      }

      const bundleAnalysisResult = bundleSelectionInfo.reduce<BundleAnalysisResult>(
        (acc, bundleItemsSelectionInfo: BundleItemsSelectionInfo, i: number) => {
          const matchingLineItems: Cart.LineItem[] = getMatchingLineItems(
            bundleProducts[i],
            cart,
            bundleItemsSelectionInfo.selectedSku
          );

          acc.variantInfos[i] = { sku: bundleItemsSelectionInfo.selectedSku, quantity: 1 };
          if (matchingLineItems?.length) {
            acc.removalPromises.push(
              dispatch(removeCartItems(matchingLineItems.map(item => item.id))).then(dispatch(loading()))
            );
            if (bundleItemsSelectionInfo.isPhysicalProduct) {
              const existingQuantity = matchingLineItems.reduce<number>(
                (a: number, item) => a + (item.quantity || 0),
                0
              );
              acc.addBannerForPhysical = true;
              acc.variantInfos[i].quantity = existingQuantity + 1;
            }
          }
          return acc;
        },
        { removalPromises: [], variantInfos: [], addBannerForPhysical: false }
      );

      if (bundleAnalysisResult.removalPromises.length) {
        await Promise.all(bundleAnalysisResult.removalPromises);
        dispatch(setRecentlyAddedBundleDiscountPercentage(state.products?.productItem?.bundleDiscountPercent));
        if (bundleAnalysisResult.addBannerForPhysical) {
          dispatch(
            setCartBundleMergeBannerMessage(
              'Please review your item quantity. The bundle you added includes a product that was already in your cart.'
            )
          );
        }
        dispatch(loading());
      }

      const anonCartInfo = getAnonCartInfo();

      const cartId = anonCartInfo?.cartId;
      const shouldPassAnonCartId = !isAuth && cartId;

      return request(ADD_BUNDLE_CART_ITEM, {
        productIds: bundleProductsIds,
        variantSKUs: bundleAnalysisResult.variantInfos,
        bundleId,
        ethicStatus: isUserSuspendedByEthics,
        ...(shouldPassAnonCartId && { cartId }),
      }).then(({ addBundleCartItem: updatedCart }: { [key: string]: any }) => {
        return updateCartDetailsInStorage(state, dispatch, updatedCart);
      });
    }
);

export const updateCartItem: any = createAction(
  'cart/UPDATE_CART_ITEM',
  (quantity: number, standingOrder: boolean, lineItemId: string, shippingMethodKey?: string, donationPrice?: string) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const state = getState();
      dispatch(loading());
      const id = cartIdSelector(state);
      const version = cartVersionSelector(state);
      const lineItem: State.LineItem | undefined = lineItemsSelector(state).find(
        (item: State.LineItem) => item.id === lineItemId
      );
      const bundleId: string | undefined = lineItem?.bundleId;

      return request(UPDATE_CART_ITEM, {
        cartId: id,
        cartVersion: version,
        quantity,
        standingOrder,
        lineItemId,
        shippingMethodKey,
        bundleId,
        donationPrice,
      }).then(({ updateCartItem: updatedCart }: { [key: string]: any }) => {
        return updateCartDetailsInStorage(state, dispatch, updatedCart);
      });
    }
);

export const removeCartItems: any = createAction(
  'cart/REMOVE_CART_ITEM',
  (lineItemIds?: string[]) => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(loading());
    const state = getState();
    const id = cartIdSelector(state);
    const version = cartVersionSelector(state);
    const isAuth = isAuthSelector(state);
    const isUserMember = isUserMemberSelector(state);
    const lineItems: State.LineItem[] = lineItemsSelector(state);
    const lineItemsToRemove = lineItemIds && lineItemIds?.length > 0 ? lineItemIds : lineItems.map(li => li.id);
    const isBundleRelatedAction: boolean = lineItems.some((lineItem: State.LineItem) => {
      if (!lineItemsToRemove.includes(lineItem.id)) return false;
      return Boolean(lineItem.bundleId);
    });

    return request(REMOVE_CART_ITEMS, {
      cartId: id,
      cartVersion: version,
      lineItemIds: lineItemsToRemove,
    }).then(async ({ removeCartItems: updatedCart }: { [key: string]: Cart.Cart }) => {
      if (isBundleRelatedAction) dispatch(clearCartSingleTimeBanners());
      if (updatedCart && isAuth && isUserMember) {
        dispatch(setProductsWithEthics(updatedCart));
      }
      if (updatedCart?.lineItems?.length === 0) {
        removeLocalStorageItem(StorageNames.activeCart);
      }
      return updateCartDetailsInStorage(state, dispatch, updatedCart);
    });
  }
);

export const updateUnpublishedVariant: any = createAction(
  'cart/UPDATE_UNPUBLISHED_VARIANT',
  (productId: string, selectedSku: string, lineItemIdToRemove: string) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      dispatch(loading());

      const state = getState();
      const id = cartIdSelector(state);
      const version = cartVersionSelector(state);

      await request(REMOVE_CART_ITEMS, {
        cartId: id,
        cartVersion: version,
        lineItemIds: [lineItemIdToRemove],
      });

      const currency = productCurrencySelector(state);
      const useProductsPriceCurrency = getFeatureToggleByKeySelector(state, USE_PRODUCTS_PRICE_CURRENCY);
      const userLocation = userLocationSelector(state);
      const isAuth = isAuthSelector(state);
      const donationPrice = donationPriceSelector(state);
      const useSuspendedRestriction = getFeatureToggleByKeySelector(state, USE_SUSPENDED_ETHICS_STATUS_RESTRICTION);
      const isUserSuspendedByEthics = isUserSuspendedSelector(state);
      const anonCartInfo = getAnonCartInfo();
      const userEthicsStatus = !!(useSuspendedRestriction && isUserSuspendedByEthics);
      const cartId = anonCartInfo?.cartId;
      const shouldPassAnonCartId = !isAuth && cartId;

      const { addCartItem: addCartItemResponse } = await request(ADD_CART_ITEM, {
        ...(shouldPassAnonCartId && { cartId }),
        productId,
        variantSKU: selectedSku,
        quantity: 1,
        standingOrder: false,
        asPartOfToBundleId: '',
        donationPrice,
        freeTrial: false,
        currencyLabel: currency.label,
        userLocation: userLocation?.country || 'US',
        useProductsPriceCurrency,
        ethicStatus: userEthicsStatus,
      });

      return updateCartDetailsInStorage(state, dispatch, addCartItemResponse);
    }
);

export const resetCartError: any = createAction('cart/RESET_CART_ERROR');

export const resetCartData: any = createAction('cart/RESET_CART_DATA');

export const addPromoCodeItem: any = createAction(
  'cart/ADD_PROMO_CODE',
  (
      promoCode: string,
      fromQueryParam: boolean = false,
      isPromoCodeExemption: boolean = false,
      isSearchingDiscountCode?: boolean
    ) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      const state = getState();
      dispatch(loading());
      const id = cartIdSelector(state);
      const version = cartVersionSelector(state);
      const cart = cartSelector(state);
      const { pathname } = getLocation(state);
      const isCartPage: boolean = pathname === getPath(Routes.CART_PAGE);
      const { id: organizationId } = centerMembershipPackageOrganizationSelector(state);

      if (promoCode) {
        return request(ADD_PROMO, {
          cartId: id || Cart.CartStates.NON_EXISTENT_CART,
          cartVersion: version || Cart.CartStates.NON_EXISTENT_CART_VERSION,
          promoCode,
          addUpsellInfo: isCartPage,
          organizationId,
          isPromoCodeExemption,
          isSearchingDiscountCode,
        })
          .then(({ addPromoCodeToCart: updatedCart }: { [key: string]: any }) => {
            return updateCartDetailsInStorage(state, dispatch, updatedCart);
          })
          .catch(e => {
            // we don't want to error out product page if promo in query param is invalid. Fail quietly.
            if (fromQueryParam) return cart;
            throw e;
          });
      }
      return Promise.reject({
        primaryApplicationError: {
          status: -1,
          message: 'Missing promo code details',
        },
      });
    }
);

export const removePromoCodeItem: any = createAction(
  'cart/REMOVE_PROMO_CODE',
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    dispatch(loading());
    const id = cartIdSelector(state);
    const version = cartVersionSelector(state);
    const promoId = appliedPromoCodeIdSelector(state);
    const { pathname } = getLocation(state);
    const isCartPage: boolean = pathname === getPath(Routes.CART_PAGE);
    if (id && version && promoId) {
      return request(REMOVE_PROMO, {
        cartId: id,
        cartVersion: version,
        promoId,
        addUpsellInfo: isCartPage,
      }).then(({ removePromoCodeFromCart: updatedCart }: { [key: string]: any }) => {
        return updateCartDetailsInStorage(state, dispatch, updatedCart);
      });
    }

    return Promise.reject({
      primaryApplicationError: {
        status: -1,
        message: 'Missing promo code details',
      },
    });
  }
);

export const setCartShippingAddress: any = createAction(
  'checkout/SET_CART_SHIPPING_ADDRESS',
  (shippingAddress: State.Address) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const { hasPhProduct, hasPhSubscriptionProduct } = phProductsInArrCheckSelector(state);
    const cart = cartSelector(state);
    const isUSAddress: boolean = shippingAddress.country === CheckoutCountriesListHash.USA.ISOAlpha3Code;
    const shippingTypeKey = !hasPhProduct // If Cart has no Physical Products in it, don't set ShippingMethod
      ? ''
      : isUSAddress
      ? Checkout.ShippingTypeKey.STANDARD
      : hasPhSubscriptionProduct
      ? Checkout.ShippingTypeKey.INTERNATIONAL_WITH_MAGAZINE
      : Checkout.ShippingTypeKey.INTERNATIONAL;
    const { id: cartId, version: cartVersion } = cart;

    const { addShippingAddress: updatedCart } = await request(ADD_CART_SHIPPING_ADDRESS, {
      cartId,
      cartVersion,
      ...shippingAddress,
      shippingTypeKey,
    });
    return updateCartDetailsInStorage(state, dispatch, updatedCart);
  }
);

export const setSelectedShippingInCart: any = createAction(
  'cart/SET_SELECTED_SHIPPING_IN_CART',
  (shippingOptionKey: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const cart = cartSelector(state);
    dispatch(isLoadingShippingOptions());
    return request(SET_SHIPPING_CART, {
      cartId: cart.id,
      cartVersion: cart.version,
      shippingOption: shippingOptionKey,
    }).then(({ setShippingOption: updatedCart }: { [key: string]: any }) =>
      updateCartDetailsInStorage(state, dispatch, updatedCart)
    );
  }
);

export const resetModule: any = createAction('cart/RESET_MODULE');

const updateCartDetailsInStorage = (state: State.Root, dispatch: Dispatch, updatedCart: any) => {
  const isAuth = isAuthSelector(state);
  if (!isAuth) {
    storeAnonCartInfo(updatedCart.id, updatedCart.version);
  }
  return updatedCart;
};

export const mergeItemsToBundle: any = createAction(
  'cart/MERGE_CART_ITEMS_TO_BUNDLE',
  (bundleId: string) =>
    async (dispatch: Dispatch, getState: () => State.Root): Promise<Cart.Cart> => {
      dispatch(loading());
      const state = getState();
      const isAuth = isAuthSelector(state);
      const anonCartInfo = getAnonCartInfo();
      const cartIdForAnonUser = !isAuth ? anonCartInfo?.cartId : null;
      if (!isAuth && !cartIdForAnonUser) {
        // If anon users with no cartId, reject
        return Promise.reject();
      }
      if (isAuth && anonCartInfo?.cartId) {
        // We should never end up here, remove the cart and continue fetching the latest cart for the registered user
        removeAnonCartInfo();
      }
      // Either logged in user OR anon with a cart id
      return request(MERGE_CART_ITEMS_TO_BUNDLE, {
        cartId: cartIdForAnonUser,
        bundleId,
      }).then(({ mergeItemsToBundle: cart }: { mergeItemsToBundle: Cart.Cart }) => {
        if (!cart && !isAuth && cartIdForAnonUser) {
          // this should not happen, clean anon cart info
          removeAnonCartInfo();
        }
        return cart;
      });
    }
);

export const processDonations: any = createAction(
  'cart/PROCESS_DONATIONS',
  (param: Cart.MembershipDonationInfo[]) => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(loading());

    return request(PROCESS_DONATION_CART_ITEMS, {
      membershipDonationInfo: param,
    }).then(({ processDonationCartItems: cart }: { processDonationCartItems: Cart.Cart }) => {
      return cart;
    });
  }
);

export const updatePreferredCurrencyByCart: any = createAction(
  'cart/UPDATE_PREFERRED_CURRENCY_BY_CART',
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const cart = cartSelector(state);
    const hasZuoraExistingPurchase = hasExistingZuoraPurchaseSelector(state);

    if (!!cart?.lineItems?.length && cart?.totalPrice?.currencyCode && !hasZuoraExistingPurchase) {
      // if there's no existing purchase and there's a lineItems in cart then update the currency
      let currency = {
        label: Product.ProductCurrencyLabel.USD,
        sign: Product.ProductCurrencySign.DOLLAR,
      };

      if (cart?.totalPrice?.currencyCode === Product.ProductCurrencyLabel.GBP) {
        currency = {
          label: Product.ProductCurrencyLabel.GBP,
          sign: Product.ProductCurrencySign.POUND,
        };
      }
      if (cart?.totalPrice?.currencyCode === Product.ProductCurrencyLabel.EUR) {
        currency = {
          label: Product.ProductCurrencyLabel.EUR,
          sign: Product.ProductCurrencySign.EURO,
        };
      }

      await dispatch(updatePreferredCurrency(currency));
    }
  }
);
export const setProductsWithEthics: any = createAction(
  'cart/SET_PRODUCTS_WITH_ETHICS',
  (cart: any) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const isUserSuspendedByEthics = isUserSuspendedSelector(state);
    const productsWithEthics: any = [];

    cart.lineItems.forEach((item: Cart.LineItem) => {
      const membershipPrice = item?.membershipPrice?.value?.centAmount || 0;
      const fullProductPrice = item?.fullProductPrice?.value?.centAmount || 0;
      const BaseProductPrice = item?.basePrice?.value?.centAmount || 0;
      const isPriceIncorrect: boolean = isUserSuspendedByEthics
        ? Boolean(membershipPrice === fullProductPrice)
        : Boolean(membershipPrice !== fullProductPrice);

      const checkAllPrice: boolean = membershipPrice === fullProductPrice && membershipPrice === BaseProductPrice;

      productsWithEthics.push({
        isPriceIncorrect: checkAllPrice ? false : isPriceIncorrect,
        productId: item.productId,
        productName: item.name,
      });
    });
    return productsWithEthics;
  }
);
