import { Dispatch } from 'redux';
import { default as request } from 'utils/GraphQLClient';
import { generatePath, matchPath } from 'react-router-dom';
import { getLocation, push } from 'connected-react-router';
import { deprovisionExamCredits } from 'modules/user/actions';
import { CheckoutCountriesTextToISOAlpha3CodeMap, User as UserUtils, FirmAdmin } from 'mxp-utils';
import {
  baseShippingAddressSelector,
  baseBillingAddressSelector,
  sameShippingAddressForBillingSelector,
} from './selectors';
import {
  personAccountDataSelector,
  learningPathwaySelector,
  currentJourneyLearningPathwaySelector,
} from '../user/selectors';
import { GET_ADDRESS_VALIDATION, GET_CART } from 'mxp-graphql-queries';
import { getSessionStorageItem, removeSessionStorageItem } from 'utils/sessionStorage';
import { getPath, getPaymentTechError, isJSON } from '../../utils';
import { StorageNames, Routes, OtherPaymentTypes } from 'constants/index';
import { initialState } from './constants';
import { CommerceTypes, Salesforce, SmartyStreets, MembershipTypes, Product, Cart } from 'mxp-schemas';
import { DeprovisionExamCreditsOnPathwaySwitchDetails } from 'mxp-schemas/src/types/salesforce';
import { cartShippingAddressSelector, lineItemsSelector } from '../cart/selectors';
import {
  isFLPSwitchSelector,
  isFLPUpgradeSelector,
  currentMembershipEntitySelector,
  inactiveMembershipEntitySelector,
} from '../../modules/membership/selectors';
import {
  setIsCartContainsInvalidBundles,
  toggleModalPaymentProcessingOpen,
  toggleSubscriptionRenewal,
} from 'modules/layouts';
import { goToOrderErrorPage, setPaymentTechCodeError, updateApplicationAndAttestation } from './actions';
import { resetCartModule } from 'modules/admin/actions';
import { setHasPreferredCurrency } from 'modules/postLoginActionsTable';
import { createPersonMasterExam } from 'modules/membership/actions';
import { flpProductListDataSelector } from 'modules/products/selectors';
import { removeLocalStorageItem } from 'utils/localStorage';

/**
 * FIX ME
 * Hacky code for edge case:
 * Should the user decide to renew their sections through "My Memberships and benefits" by clicking to upgrade
 * Instead of through "My Sections"
 *
 * Prevent the prorated price from being used
 */

export enum CimaCandidateSkus {
  PQ = 'CIMAMEMCANC22',
  L4 = 'APPRENT-L4',
  L7 = 'APPRENT-L7',
  AICPA_REG = 'MEMREGSC22',
}

const flpCoreSlug = 'flp-skills-core'; // Used for function: isFLPTierBeingChangedToCore

export const forceNonProrationPrices = (lineItems: any, credentialItems: any, valuesToOverride: any) => {
  const hasSectionProductType = lineItems.filter((lineItem: any) => lineItem.productType === 'section-type').length > 0;
  const hasExistingMipCredential = credentialItems.find((credentialItem: any) =>
    credentialItem.sku.includes(MembershipTypes.CimaMip.MIP)
  )?.since;
  const hasMipCredentialInCart =
    lineItems.filter((lineItem: any) => lineItem.productSlug === MembershipTypes.CimaCredentialProductSlug.CIMA_MIP)
      .length > 0;
  if (hasSectionProductType || (hasMipCredentialInCart && hasExistingMipCredential)) {
    return valuesToOverride;
  }
  return {};
};

export const getLocalCheckoutAction =
  () =>
  (dispatch: Dispatch, getState: () => State.Root): State.Checkout | null => {
    const checkout: State.Checkout | null = getSessionStorageItem(StorageNames.checkout) as State.Checkout | null;
    if (!checkout) return null;
    const state: State.Root = getState();
    const { pathname } = getLocation(state as any);
    const isRouteCheckout: boolean =
      pathname === generatePath(getPath(Routes.CHECKOUT_PAGE)) ||
      Boolean(matchPath(pathname, { path: getPath(Routes.PAYPAL_PAYMENT_CHECKOUT_PROCEED), exact: true }));
    if (isRouteCheckout) {
      const cartShippingAddress: CommerceTypes.Address | undefined = cartShippingAddressSelector(state);

      if (!cartShippingAddress && checkout.savedShippingAddress) {
        // TODO Update cart with address if no address in cart and saved shipping address exist
      }
      return {
        ...checkout,
        // must be reset, since we must to query data each page reload
        addressAutocompleteItems: initialState.addressAutocompleteItems,
        isLoadingAutocompleteItems: initialState.isLoadingAutocompleteItems,
        paymentTechCodeError: initialState.paymentTechCodeError,
        billingAddress: {
          ...checkout.billingAddress,
          addressType: Salesforce.HomeAddressType.OFFICE_MAILING,
        },
        shippingAddress: {
          ...checkout.shippingAddress,
          addressType: Salesforce.HomeAddressType.OFFICE_MAILING,
        },
        paypalSessionIdentifier: '',
      };
    }
    removeSessionStorageItem(StorageNames.checkout);
    return null;
  };

interface SubscriptionProps {
  id: string;
  orderNumber: string;
  subscriptionStartDate: string;
  billToAddressLine1: string;
  billToAddressLine2: string;
  billToCity: string;
  billToState: string;
  billToPostalCode: string;
  billToCountry: string;
  shipToAddressLine1: string;
  shipToAddressLine2: string;
  shipToCity: string;
  shipToState: string;
  shipToPostalCode: string;
  shipToCountry: string;
}

export type Response = Pick<State.Checkout, 'savedShippingAddress' | 'savedBillingAddress'>;

// can be deleted after membership live
export const getSavedShippingBillingAddresses = (subscriptions: SubscriptionProps[]): Response => {
  return subscriptions.slice(0, 1).reduce(
    (acc: Response, item: SubscriptionProps) => {
      const { shipToAddressLine1, shipToAddressLine2, shipToCity, shipToState, shipToPostalCode, shipToCountry } = item;
      if (shipToAddressLine1 && shipToCity && shipToState && shipToCountry) {
        acc.savedShippingAddress = {
          addressLine1: shipToAddressLine1,
          addressLine2: shipToAddressLine2 || '',
          city: shipToCity,
          state: shipToState,
          zipCode: shipToPostalCode || '',
          country: shipToCountry,
        };
      }
      const { billToAddressLine1, billToAddressLine2, billToCity, billToState, billToPostalCode, billToCountry } = item;
      if (billToAddressLine1 && billToCity && billToState && billToPostalCode) {
        acc.savedBillingAddress = {
          addressLine1: billToAddressLine1,
          addressLine2: billToAddressLine2 || '',
          city: billToCity,
          state: billToState,
          zipCode: billToPostalCode,
          country: billToCountry,
        };
      }
      return acc;
    },
    {
      savedShippingAddress: null,
      savedBillingAddress: null,
    }
  );
};

export const getZuoraAddresses = (
  state: State.Root
): { shippingAddress: State.Address; billingAddress: State.Address } => {
  const sameShippingAddressForBilling: boolean = sameShippingAddressForBillingSelector(state);
  let shippingAddress: State.Address = baseShippingAddressSelector(state);
  let billingAddress: State.Address = sameShippingAddressForBilling
    ? shippingAddress
    : baseBillingAddressSelector(state);

  const isFLPUpgrade = isFLPUpgradeSelector(state);
  const isFLPSwitch = isFLPSwitchSelector(state);

  if (isFLPSwitch || isFLPUpgrade) {
    const personAccountData = personAccountDataSelector(state);
    const personAccountDataAdress = {
      firstName: personAccountData.firstName,
      lastName: personAccountData.lastName,
      company: '',
      addressLine1: personAccountData.address.addressLine1,
      addressLine2: personAccountData.address.addressLine2,
      city: personAccountData.address.city,
      county: '',
      state: personAccountData.address.state,
      zipCode: personAccountData.address.zipCode,
      country: personAccountData.address.country,
    };

    shippingAddress =
      shippingAddress.addressLine1 || shippingAddress.addressLine2 ? shippingAddress : personAccountDataAdress;
    billingAddress =
      billingAddress.addressLine1 || billingAddress.addressLine2 ? billingAddress : personAccountDataAdress;
  }

  return {
    shippingAddress: {
      ...shippingAddress,
      country: CheckoutCountriesTextToISOAlpha3CodeMap[shippingAddress.country] || shippingAddress.country, // saved addresses in SF have text, new addresses from dropdown have code
    },
    billingAddress: {
      ...billingAddress,
      country: CheckoutCountriesTextToISOAlpha3CodeMap[billingAddress.country] || billingAddress.country,
    },
  };
};

/**
 * @name getSmartyStreetsValidation
 * @description Address validation in SmartyStreets for B2C and APPC applications
 *
 * @param address - shipping address
 * @param query - graphql query (most likely GET_ADDRESS_VALIDATION)
 *
 * @returns Address response from graphql
 */
export const getSmartyStreetsValidation = async (shippingAddress: State.Address, billingAddress?: State.Address) => {
  try {
    let response: any;

    const transFormedAddress = {
      shippingAddress,
      billingAddress,
    };

    response = await request(GET_ADDRESS_VALIDATION, transFormedAddress);

    const addressValidation = response.getAddressValidation;
    return {
      smartystreetsValidation: {
        validationNeeded: addressValidation?.shippingAddressValidation.validationNeeded,
        shippingAddress: addressValidation?.shippingAddressValidation.shippingAddress,
        billingValidationNeeded: addressValidation?.billingAddressValidation?.validationNeeded,
        billingAddress: addressValidation?.billingAddressValidation?.shippingAddress,
      },
      isShippingAddressValid: !addressValidation?.shippingAddressValidation.validationNeeded,
      isBillingAddressValid: !addressValidation?.billingAddressValidation?.validationNeeded,
      isShippingEmbargoed: addressValidation?.shippingAddressValidation.isEmbargoed,
      isBillingEmbargoed: addressValidation?.billingAddressValidation?.isEmbargoed,
    };
  } catch (e) {
    return { isShippingAddressValid: true, isBillingAddressValid: true, skipped: true }; // skip SmartyStreet validation in case of technical errors
  }
};

/**
 * @name getAddressValidationScenario
 * @description to check for validation reasons that need more user input in Address
 *
 * @param reason - reason for extra validation
 *
 * @returns SmartyStreets.ValidationCheckoutToggle enum
 */
export const getAddressValidationScenario = (reason: string): SmartyStreets.ValidationCheckoutToggle => {
  if (
    [
      SmartyStreets.ValidationReason.CLEAN_UP,
      SmartyStreets.ValidationReason.AMBIGUOUS,
      SmartyStreets.ValidationReason.SECONDARY_ADD_UNRECOGNIZED,
    ].includes(reason as SmartyStreets.ValidationReason)
  ) {
    return SmartyStreets.ValidationCheckoutToggle.MODAL;
  }
  if ([SmartyStreets.ValidationReason.SECONDARY_ADD_MISSING].includes(reason as SmartyStreets.ValidationReason)) {
    return SmartyStreets.ValidationCheckoutToggle.SECONDARY;
  }
  if ([SmartyStreets.ValidationReason.INVALID].includes(reason as SmartyStreets.ValidationReason)) {
    return SmartyStreets.ValidationCheckoutToggle.INVALID;
  }
  return SmartyStreets.ValidationCheckoutToggle.NONE;
};

export const getCTAddress = (returned: any) => ({
  addressLine1: returned?.addressLine1 || '',
  addressLine2: returned?.addressLine2 || '',
  city: returned?.city || '',
  county: returned?.county || '',
  zipCode: returned?.zipCode || '',
  state: returned?.state || '',
  country: returned?.country || 'USA',
});

export const parseAddressResponses = async (
  setFunction: (zuoraAddress: State.Address, sfAddress?: Partial<Salesforce.ContactPointAddress>) => void,
  responseAddress: SmartyStreets.ValidationAddress,
  validationNeeded: boolean,
  saved: boolean
) => {
  const reason = responseAddress?.reason || '';
  const scenario: SmartyStreets.ValidationCheckoutToggle = getAddressValidationScenario(reason);

  // a new address which passes validation
  if (!validationNeeded && !saved) {
    const returned = responseAddress?.suggestions[0]?.fullSuggestion;
    if (returned) {
      const zuoraAddress: State.Address = {
        addressLine1: returned.street || '',
        addressLine2: returned.streetSec || '',
        city: returned.city || '',
        county: returned.county || '',
        zipCode: returned.zipcode || '',
        state: returned.state || '',
        country: returned.country || 'USA',
      };
      const sfAddress: Partial<Salesforce.ContactPointAddress> = {
        addressLine1: returned.street || '',
        addressLine2: returned.streetSec || '',
        city: returned.city || '',
        country: returned.country || 'USA',
        zipCode: returned.zipcode || '',
        state: returned.state || '',
        // county: returned.county || '',
        lat: returned.lat || '',
        long: returned.long || '',
      };
      await setFunction(zuoraAddress, sfAddress);
    }
  }
  return scenario;
};

const createCard = (paymentMethod: any): any => {
  return paymentMethod?.map(
    ({
      id,
      email,
      accountKey,
      accountHolderInfo,
      bankAccountNumber,
      accountNumber,
      isDefault,
      bankAccountName,
    }: any): any => ({
      cardHolderInfo: {
        cardHolderName: email
          ? email
          : bankAccountNumber
          ? accountHolderInfo.email || bankAccountName
          : accountHolderInfo.accountHolderName,
      },
      id,
      cardNumber: email ? accountKey : bankAccountNumber ? bankAccountNumber : accountNumber,
      defaultPaymentMethod: isDefault,
      cardType: email
        ? OtherPaymentTypes.PAYPAL
        : bankAccountNumber
        ? OtherPaymentTypes.ACH
        : OtherPaymentTypes.BANKTRANSFER,
    })
  );
};

export const createPaymentMethods = (cima: any, association: any): any => {
  const cimaPaypalCard = createCard(cima?.paypal);
  const associationPaypalCard = createCard(association?.paypal);
  const cimaAchCard = createCard(cima?.ach);
  const associationAchCard = createCard(association?.ach);
  const cimaBankTransferCard = createCard(cima?.bankTransfer);
  const associationBankTransferCard = createCard(association?.bankTransfer);

  let cimaCard = cima?.creditCards?.concat(cimaPaypalCard, cimaAchCard, cimaBankTransferCard);
  cimaCard = cimaCard && cimaCard.filter((elm: any) => elm !== undefined);

  let associationCard = association?.creditCards?.concat(
    associationPaypalCard,
    associationAchCard,
    associationBankTransferCard
  );
  associationCard = associationCard && associationCard.filter((elm: any) => elm !== undefined);

  return { cimaCard, associationCard };
};

export const getUserMembershipEntity = (state: State.Root): any => {
  const currentMembershipEntity = currentMembershipEntitySelector(state);

  if (currentMembershipEntity !== undefined) return currentMembershipEntity;

  const inactiveMembershipEntity = inactiveMembershipEntitySelector(state);
  return inactiveMembershipEntity;
};

const paymentTechErrorHandler = (reason: string, dispatch: Dispatch) => {
  if (isJSON(reason)) {
    const reasonObject = JSON.parse(reason);
    const paymentTechError = getPaymentTechError(reasonObject?.gatewayResponse?.code);
    if (paymentTechError) {
      dispatchEvent(setPaymentTechCodeError(reasonObject?.gatewayResponse?.code));
    } else {
      dispatch(goToOrderErrorPage());
    }
  } else {
    dispatch(goToOrderErrorPage());
  }
};

const getCandidateLearningPathwayFromProduct = (productType: string, sku: string): string => {
  if (productType === Product.ProductTypeLabels.FLP || productType === Product.ProductType.FLP)
    return MembershipTypes.Pathway.FLP;
  const skuMap = {
    [CimaCandidateSkus.PQ as string]: MembershipTypes.Pathway.PQ,
    [CimaCandidateSkus.L4 as string]: MembershipTypes.Pathway.APPRENTICE_L4,
    [CimaCandidateSkus.L7 as string]: MembershipTypes.Pathway.APPRENTICE_L7,
    [CimaCandidateSkus.AICPA_REG as string]: MembershipTypes.Pathway.FLP,
  };
  return skuMap?.[sku] || '';
};

interface DeprovisionExamCreditDetails {
  personAccountData: State.PersonAccountData;
  currentMembershipWithSku: Salesforce.MembershipProductAndOrder;
  currentMembershipPathwayName?: MembershipTypes.Pathway | undefined;
  examCreditProducts?: Cart.LineItem[] | undefined;
}

export const createZuoraOrderHelper = {
  handleUnpublishedProduct: async (options: any, isFreeCartPayment: boolean, dispatch: Dispatch) => {
    const { checkIfUnpublishedProduct } = options ?? {};
    if (checkIfUnpublishedProduct || isFreeCartPayment) {
      const {
        getCart: { lineItems: cartItems },
      } = await request(GET_CART, { cartId: '', recalculate: false, addUpsellInfo: false });
      const hasUnpublishedProductOrVariant = cartItems.some(
        (cartItem: any) => cartItem.mainProductPublished === false || cartItem.variantProductPublished === false
      );
      if (hasUnpublishedProductOrVariant) {
        dispatch(toggleModalPaymentProcessingOpen());
        dispatch(setPaymentTechCodeError(''));
        dispatch(resetCartModule());
        dispatch(push(getPath(Routes.CART_PAGE)));
        return { success: false, error: 'Product format no longer available' };
      }
    }
  },
  handleSubscriptionRenewalToggle: async (
    activeMembershipSubscription: any,
    membershipAutoRenewEnabled: boolean | undefined,
    shouldEnableAutoRenewForActiveMembership: boolean | undefined,
    hasFLPProduct: boolean,
    productsToBeDisabledIfMembershipIsDisabled: any[],
    productSlug: any,
    dispatch: Dispatch
  ) => {
    if (
      activeMembershipSubscription?.subscriptionNumber &&
      ((typeof membershipAutoRenewEnabled !== 'undefined' &&
        membershipAutoRenewEnabled !== activeMembershipSubscription.autoRenewEnabled) ||
        shouldEnableAutoRenewForActiveMembership) &&
      !hasFLPProduct // don't update membership subscription if we're purchasing FLP product.
    ) {
      const subscriptions = UserUtils.conditionalFunction(
        productsToBeDisabledIfMembershipIsDisabled.length > 0,
        [activeMembershipSubscription?.subscriptionNumber, ...productsToBeDisabledIfMembershipIsDisabled].filter(
          (v, i, a) => a.indexOf(v) === i
        ),
        activeMembershipSubscription?.subscriptionNumber
      );
      if (shouldEnableAutoRenewForActiveMembership || !Array.isArray(subscriptions)) {
        await dispatch(
          toggleSubscriptionRenewal(
            activeMembershipSubscription?.subscriptionNumber,
            UserUtils.conditionalFunction(shouldEnableAutoRenewForActiveMembership, true, membershipAutoRenewEnabled),
            productSlug
          )
        );
      } else {
        for await (const subscription of subscriptions) {
          await dispatch(toggleSubscriptionRenewal(subscription, membershipAutoRenewEnabled, productSlug));
        }
      }
    }
  },

  handleZuoraResponse: (
    res: any,
    learningPathway: string,
    isCheckPayment: boolean,
    cartLineItems: any,
    dispatch: Dispatch,
    deprovisionDetails?: DeprovisionExamCreditDetails
  ) => {
    const zuoraSuccess: boolean = res.createOrder.success;
    const commerceToolsSuccess: boolean = res.createOrder.commerceToolsSuccess;
    const isCartHasInvalidQuantities: boolean = res.createOrder.isCartHasInvalidQuantities;
    const isCartContainsInvalidBundles: boolean = res.createOrder.isCartContainsInvalidBundles;
    const isCartHasRestrictedMix: boolean = res.createOrder.isCartHasRestrictedMix;
    const isCartContainsNonEligibleFreeTrials: boolean = res.createOrder.isCartContainsNonEligibleFreeTrials;
    const isCartContainsActiveFreeTrials: boolean = res.createOrder.isCartContainsActiveFreeTrials;
    const isCartContainsSubscribedFreeTrials: boolean = res.createOrder.isCartContainsSubscribedFreeTrials;
    const isCartContainsFreeTrialsUsedWithinPeriod: boolean = res.createOrder.isCartContainsSubscribedFreeTrials;

    const cartMembershipProduct = cartLineItems?.find((item: any) =>
      [Product.ProductType.FLP, Product.ProductType.MEMBERSHIP]?.includes(item?.productType)
    );

    const productPathway =
      getCandidateLearningPathwayFromProduct(cartMembershipProduct?.productType, cartMembershipProduct?.variant?.sku) ||
      learningPathway;

    removeLocalStorageItem(StorageNames.activeCart);

    if (
      FirmAdmin.hasTruthyValue(
        isCartHasInvalidQuantities,
        isCartHasRestrictedMix,
        isCartContainsInvalidBundles,
        isCartContainsNonEligibleFreeTrials,
        isCartContainsActiveFreeTrials,
        isCartContainsSubscribedFreeTrials,
        isCartContainsFreeTrialsUsedWithinPeriod
      )
    ) {
      dispatch(resetCartModule());
      UserUtils.conditionalFunction(isCartContainsInvalidBundles, dispatch(setIsCartContainsInvalidBundles(true)));
      dispatch(push(getPath(Routes.CART_PAGE)));
    } else if (zuoraSuccess && commerceToolsSuccess) {
      // CREATE EXAM EXEMPTIONS FOR CIMA MEMBERSHIP CANDIDATE
      dispatch(createPersonMasterExam([], productPathway));
      dispatch(updateApplicationAndAttestation());
      dispatch(resetCartModule());
      UserUtils.conditionalFunction(
        isCheckPayment,
        dispatch(push(generatePath(`${getPath(Routes.MY_RECEIPTS)}?isPaperBilling=true`))),
        dispatch(
          push(
            generatePath(getPath(Routes.CHECKOUT_PAGE), {
              orderNumber: res.createOrder.orderNumber,
            }),
            {
              isRedirectedFromCheckout: true,
            }
          )
        )
      );
      dispatch(setHasPreferredCurrency());

      if (deprovisionDetails) {
        deprovisionExamCreditsOnPathwaySwitch(
          dispatch,
          deprovisionDetails.currentMembershipPathwayName,
          deprovisionDetails.currentMembershipWithSku,
          deprovisionDetails.examCreditProducts,
          deprovisionDetails.personAccountData
        );
      }
    } else if (FirmAdmin.areAllTruthy(zuoraSuccess, !commerceToolsSuccess)) {
      dispatch(updateApplicationAndAttestation());
      dispatch(goToOrderErrorPage({ commerceToolsFailed: true }));
      dispatch(setHasPreferredCurrency());
      Promise.reject(new Error('Zuora order has been processed, error creating order in CommerceTools'));
    } else {
      if (res.createOrder?.currency) dispatch(setHasPreferredCurrency());

      if (res.createOrder?.reasons?.[0]?.message) {
        paymentTechErrorHandler(res.createOrder.reasons[0].message || '', dispatch);
      } else {
        dispatch(goToOrderErrorPage());
        Promise.reject(new Error('Error creating zuora order'));
      }
    }
  },
};

export const isFLPTierBeingChangedToCore = (state: State.Root) => {
  // Get FLP product tier that has been added in cart:
  const cartLineItems = lineItemsSelector(state);
  const flpProductInCart = cartLineItems?.find(item => {
    return item?.productType === Product.ProductType.FLP;
  });
  const FLPTierInCart = flpProductInCart?.productSlug || '';

  // Current FLP tier of user
  const currentFLPTypeOfUser = flpProductListDataSelector(state)?.productSlug || '';

  // FLP Tier is not being changed if:
  // There is not an FLP Product in cart or the user does not have an existing FLP membership
  if (!FLPTierInCart || !currentFLPTypeOfUser) return false;

  // If the User has an FLP membership, and is checking out another FLP membership, see if they are switching to Core.
  const isFLPTierBeingChangedToCore: boolean = FLPTierInCart === flpCoreSlug && currentFLPTypeOfUser !== flpCoreSlug;
  return isFLPTierBeingChangedToCore;
};

export const getLearningPathwayProductInCart = (state: State.Root) => {
  const cartLineItems = lineItemsSelector(state);
  const cartMembershipProduct = cartLineItems?.find((item: any) => {
    return (
      item?.productType === Product.ProductType.FLP ||
      (item?.productType === Product.ProductType.MEMBERSHIP &&
        item?.productSlug === MembershipTypes.CimaMembershipProductSlug.CIMA_CANDIDATE_PRODUCT)
    );
  });

  const learningPathwayFromProduct = getCandidateLearningPathwayFromProduct(
    cartMembershipProduct?.productType as string,
    cartMembershipProduct?.variant?.sku as string
  );

  return learningPathwayFromProduct;
};

export const getLearningPathway = (hasMembershipProduct: boolean, hasFLPProduct: boolean, state: State.Root) => {
  const isMembershipOrder = FirmAdmin.hasTruthyValue(hasMembershipProduct, hasFLPProduct);
  const savedLearningPathway = UserUtils.conditionalFunction(isMembershipOrder, learningPathwaySelector(state), '');
  const learningPathwayFromProduct = getLearningPathwayProductInCart(state);

  const learningPathway =
    learningPathwayFromProduct || currentJourneyLearningPathwaySelector(state) || savedLearningPathway;

  return learningPathway;
};

export const deprovisionExamCreditsOnPathwaySwitch = (
  dispatch: Dispatch,
  currentMembershipPathwayName: MembershipTypes.Pathway | undefined,
  currentMembershipWithSku: Salesforce.MembershipProductAndOrder,
  examCreditProducts: Cart.LineItem[] | undefined,
  personAccountData?: State.PersonAccountData
) => {
  const aicpaId = personAccountData?.aicpaId as string;
  const examCreditCtOrderIdAndSku = examCreditProducts?.map(product => {
    return {
      ctOrderId: product?.orderId || '',
      sku: product.variant?.sku || '',
    };
  });

  const deprovisionExamCreditsProps: DeprovisionExamCreditsOnPathwaySwitchDetails = {
    orderDetailsWithOktaId: {
      aicpaId,
    },
    deprovisioningDetails: {
      isDeprovisioning: true,
      skusForItemsBeingDeprovisioned: [],
      deprovisionReason: 'Pathway Switched',
    },
    examCreditInfo: examCreditCtOrderIdAndSku,
    learningPathway: currentMembershipPathwayName,
    membershipSku: currentMembershipWithSku?.membership?.membershipTerm?.productId,
  };
  dispatch(deprovisionExamCredits(deprovisionExamCreditsProps));
};
